import React, { useEffect, useState } from "react";
import Styles from "./DashboardLogin.module.scss";
import lmauthfeatured from "../../assets/images/lm-auth-featured.svg";
import lmLogo from "../../assets/images/learn-me-logo-colored.svg";
import ImageView from "../../components/Image/ImageView";
import { TextFieldFormik } from "../../components/form-controls/input/text.field.formik";
import UserTypeTabs from "./UserTypeTabs";
import { Form } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { login, otpLogin } from "../../services/apis/authService";
import { createNotification } from "../../helpers/createNotifications";
import { emailRegex } from "../../helpers/utils";
import OtpModal from "./OtpModal";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../../utils/utilAuth";

const DashboardLogin = () => {
  const [tab, setTab] = useState("director");
  const alreadyCreds = JSON.parse(localStorage.getItem(tab));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [currentUserData, setCurrentUserData] = useState();
  const dispatch = useDispatch();

  const [creds, setCreds] = useState({
    user: {
      email: alreadyCreds?.user?.email,
      password: alreadyCreds?.user?.password,
    },
    isModalOpen,
  });
  const [disable, setDisbale] = useState(true);
  const [remember, setRemember] = useState();

  const verifyUser = async (data) => {
    const response = await otpLogin({ ...data });
    if (response?.data?.token) {
      loginSuccess(dispatch, response.data);
    } else if (response?.data?.authenticated && response?.data?.otp_require) {
      setCurrentUserData(data);
      setIsModalOpen(true);
    } else {
      createNotification(
        "error",
        response?.data?.message ||
          response?.response?.data?.response?.error ||
          response?.response?.data?.error ||
          "Invalid credentials"
      );
    }
  };

  useEffect(() => {
    if (creds?.user?.email?.length && creds.user?.password?.length) {
      if (creds?.user?.email?.length && !emailRegex.test(creds?.user?.email)) {
        setEmailError(true);
        setDisbale(true);
        return;
      } else {
        setEmailError(false);
      }
      setDisbale(false);
    } else {
      setDisbale(true);
    }
    if (alreadyCreds) {
      setRemember(true);
    }
  }, [creds]);
  useEffect(() => {
    if (!alreadyCreds) {
      setCreds({
        user: {
          email: "",
          password: "",
          role: tab,
        },
      });
      setRemember(false);
    } else {
      setCreds({
        user: {
          email: alreadyCreds?.user?.email,
          password: alreadyCreds?.user?.password,
          role: tab,
        },
      });
    }
  }, [tab]);

  return (
    <section className={Styles.DashboardLogin}>
      <div className={Styles.DashboardLogin__FormSection}>
        <div className={Styles.DashboardLogin__FormSectionWrapper}>
          <a href="#">
            <ImageView src={lmLogo} className="img-fluid" />
          </a>
          <Form className="w-100 d-flex flex-column gap-70">
            <div className="w-100 d-flex flex-column gap-70">
              <UserTypeTabs
                setTab={setTab}
                setCreds={setCreds}
                creds={creds}
                setRemember={setRemember}
                remember={remember}
                emailError={emailError}
              ></UserTypeTabs>
            </div>
            <Button
              className="w-100 lm-form-btn lm-form-btn-primary f-16 f-w-600"
              disabled={disable}
              onClick={() => {
                if (remember) {
                  localStorage.setItem(
                    tab,
                    JSON.stringify({
                      user: {
                        email: creds?.user?.email,
                        password: creds.user?.password,
                        // role: tab,
                      },
                    })
                  );
                } else {
                  localStorage.removeItem(tab);
                }

                verifyUser(creds);
              }}
            >
              Sign In
            </Button>
          </Form>
        </div>
      </div>
      <div className={Styles.DashboardLogin__ImageSection}>
        <section className={Styles.DashboardLogin__ImageSection_Wrapper}>
          <ImageView src={lmauthfeatured} className="img-fluid" />
        </section>
      </div>

      <OtpModal
        isModalOpen={isModalOpen}
        CurrentUserDetails={currentUserData}
        setIsModalOpen={setIsModalOpen}
      />
    </section>
  );
};

export default DashboardLogin;

import React from "react";
import "./LinearGraph.css";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const LinearGraph = ({ graphData, goalColorMap }) => {
  const goalNames = graphData[0]
    ? Object.keys(graphData[0]).filter((key) => key !== "appointment_date")
    : [];

  return (
    <ResponsiveContainer width="100%" height={350}>
      <LineChart data={graphData} margin={{ top: 10, right: 10, left: 10, bottom: 30 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="appointment_date"
          padding={{ left: 30, right: 30 }}
          label={{
            value: "Appointment Date",
            position: "insideBottom",
            style: { fontWeight: 'bold' },
            offset: -20,
          }} 
        />
        <YAxis
          label={{
            value: "Score %",
            angle: -90,
            position: "insideLeft",
            style: { fontWeight: 'bold' },

          }}
        />
        <Tooltip />

        {goalNames.map((goalName, index) => (
          <Line
            key={goalName}
            type="linear"
            dataKey={goalName}
            name={goalName}
            stroke={goalColorMap[goalName]}
            strokeWidth={2}
            activeDot={{ r: 10 }}
            dot={{ r: 6 }}
            connectNulls={true}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default LinearGraph;

import { TimePicker, Tooltip } from "antd";
import { HiOutlineDocumentDownload } from "react-icons/hi";
import PauseBtn from "../assets/images/Pause-red-btn.svg";
import archiveIcon from "../assets/images/archive.svg";
import archiveGoalIcon from "../assets/images/cross-red.svg";
import playBtn from "../assets/images/play_arrow.svg";
import Addicon from "../assets/svg/add.svg";
import editicon from "../assets/svg/editicon.svg";
import ImageView from "../components/Image/ImageView";
import {
  UnarchiveGoalIcon,
  infoIconYellow,
  unlockOrange,
  EyeIcon,
} from "./constants";
import "./TableDataStyles.css";
import deleteTrashRed from "../assets/images/delete-trash-red.svg";
import globalSettingIcon from "../assets/images/global-setting-icon.svg";
import infoIcon from "../assets/images/info-icon-yellow.svg";
import crossIcon from "../assets/images/newCross.svg";
import CustomTimePicker from "../Containers/Appointment/custom-time-picker";

import {
  DataSheetIcon,
  OnBoardingFormIcon,
  StatusCompletedIcon,
  StatusPendingIcon,
  UnarchiveRed,
  addNotesIcon,
  alarmIconBlack,
  crossRed,
  forwardToInboxIcon,
  locationIconBlack,
  lockLockedIconGrey,
  locklockedOrangeIcon,
  serviceNoteIconColored,
  tickGreen,
} from "./constants";

import { useNavigate } from "react-router-dom";
import { GetCurrentUser } from "../utils/utilAuth";
import { timeSlotsEnd, timeSlotsStart } from "./clientdata";
import CustomSelect from "../Containers/Appointment/custom-select";
import { TextFieldFormik } from "../components/form-controls/input/text.field.formik";
import moment from "moment";
import dayjs from "dayjs";
import { useState } from "react";

const getLetter = (name) => {
  const words = name?.split(" ").filter((word) => word.trim() !== "");
  let extractedLetters;
  if (words?.length >= 1) {
    const firstLetterWord1 = words[0][0]?.toUpperCase();
    const firstLetterWord2 = words[1]?.[0]?.toUpperCase() || "";
    extractedLetters = firstLetterWord1 + firstLetterWord2;
  }
  return extractedLetters;
};

export const PanelSessionsTableData = [
  {
    key: "1",
    name: "Emily Joe",
    Email: "emily.joe@example.com",
    Technician: "Adam lambert",
    Goals: "Accepting No",
    Date: "20/3/23",
    Status: <span className="cursor-pointer">{StatusCompletedIcon}</span>,
  },
  {
    key: "2",
    name: "Emily Joe",
    Email: "emily.joe@example.com",
    Technician: "Adam lambert",
    Goals: "Accepting No",
    Date: "20/3/23",
    Status: <span className="cursor-pointer">{StatusPendingIcon}</span>,
  },
  {
    key: "3",
    name: "Emily Joe",
    Email: "emily.joe@example.com",
    Technician: "Adam lambert",
    Goals: "Accepting No",
    Date: "20/3/23",
    Status: <span className="cursor-pointer">{StatusPendingIcon}</span>,
  },
  {
    key: "4",
    name: "Emily Joe",
    Email: "emily.joe@example.com",
    Technician: "Adam lambert",
    Goals: "Accepting No",
    Date: "20/3/23",
    Status: <span className="cursor-pointer">{StatusPendingIcon}</span>,
  },
];

export const DailySessionsClientsTD = [
  {
    key: "1",
    Date: <span className="f-w-400">20/3/23</span>,
    Time: "9:30 AM",
    Technician: <span className="f-w-600">Mathew Johnson</span>,
    Status: lockLockedIconGrey,
    Actions: (
      <div className="d-flex flex-wrap gap-20">
        <span className="lm-tag ">Data Sheet</span>
        <span className="lm-tag ">Service Note</span>
      </div>
    ),
  },
  {
    key: "1",
    Date: <span className="f-w-400">20/3/23</span>,
    Time: "9:30 AM",
    Technician: <span className="f-w-600">Adam Lambert</span>,
    Status: locklockedOrangeIcon,
    Actions: (
      <div className="d-flex flex-wrap gap-20">
        <span className="lm-tag ">Data Sheet</span>
        <span className="lm-tag ">Service Note</span>
      </div>
    ),
  },
  {
    key: "1",
    Date: <span className="f-w-400">20/3/23</span>,
    Time: "9:30 AM",
    Technician: <span className="f-w-600">Mathew Johnson</span>,
    Status: lockLockedIconGrey,
    Actions: (
      <div className="d-flex flex-wrap gap-20">
        <span className="lm-tag ">Data Sheet</span>
        <span className="lm-tag ">Service Note</span>
      </div>
    ),
  },
  {
    key: "1",
    Date: <span className="f-w-400">20/3/23</span>,
    Time: "9:30 AM",
    Technician: <span className="f-w-600">Adam Lambert</span>,
    Status: locklockedOrangeIcon,
    Actions: (
      <div className="d-flex flex-wrap gap-20">
        <span className="lm-tag ">Data Sheet</span>
        <span className="lm-tag ">Service Note</span>
      </div>
    ),
  },
  {
    key: "1",
    Date: <span className="f-w-400">20/3/23</span>,
    Time: "9:30 AM",
    Technician: <span className="f-w-600">Mathew Johnson</span>,
    Status: lockLockedIconGrey,
    Actions: (
      <div className="d-flex flex-wrap gap-20">
        <span className="lm-tag ">Data Sheet</span>
        <span className="lm-tag ">Service Note</span>
      </div>
    ),
  },
  {
    key: "1",
    Date: <span className="f-w-400">20/3/23</span>,
    Time: "9:30 AM",
    Technician: <span className="f-w-600">Adam Lambert</span>,
    Status: locklockedOrangeIcon,
    Actions: (
      <div className="d-flex flex-wrap gap-20">
        <span className="lm-tag ">Data Sheet</span>
        <span className="lm-tag ">Service Note</span>
      </div>
    ),
  },
  {
    key: "1",
    Date: <span className="f-w-400">20/3/23</span>,
    Time: "9:30 AM",
    Technician: <span className="f-w-600">Mathew Johnson</span>,
    Status: lockLockedIconGrey,
    Actions: (
      <div className="d-flex flex-wrap gap-20">
        <span className="lm-tag ">Data Sheet</span>
        <span className="lm-tag ">Service Note</span>
      </div>
    ),
  },
  {
    key: "1",
    Date: <span className="f-w-400">20/3/23</span>,
    Time: "9:30 AM",
    Technician: <span className="f-w-600">Adam Lambert</span>,
    Status: locklockedOrangeIcon,
    Actions: (
      <div className="d-flex flex-wrap gap-20">
        <span className="lm-tag ">Data Sheet</span>
        <span className="lm-tag ">Service Note</span>
      </div>
    ),
  },
  {
    key: "1",
    Date: <span className="f-w-400">20/3/23</span>,
    Time: "9:30 AM",
    Technician: <span className="f-w-600">Mathew Johnson</span>,
    Status: lockLockedIconGrey,
    Actions: (
      <div className="d-flex flex-wrap gap-20">
        <span className="lm-tag ">Data Sheet</span>
        <span className="lm-tag ">Service Note</span>
      </div>
    ),
  },
  {
    key: "1",
    Date: <span className="f-w-400">20/3/23</span>,
    Time: "9:30 AM",
    Technician: <span className="f-w-600">Adam Lambert</span>,
    Status: locklockedOrangeIcon,
    Actions: (
      <div className="d-flex flex-wrap gap-20">
        <span className="lm-tag ">Data Sheet</span>
        <span className="lm-tag ">Service Note</span>
      </div>
    ),
  },
  {
    key: "1",
    Date: <span className="f-w-400">20/3/23</span>,
    Time: "9:30 AM",
    Technician: <span className="f-w-600">Mathew Johnson</span>,
    Status: lockLockedIconGrey,
    Actions: (
      <div className="d-flex flex-wrap gap-20">
        <span className="lm-tag ">Data Sheet</span>
        <span className="lm-tag ">Service Note</span>
      </div>
    ),
  },
  {
    key: "1",
    Date: <span className="f-w-400">20/3/23</span>,
    Time: "9:30 AM",
    Technician: <span className="f-w-600">Adam Lambert</span>,
    Status: locklockedOrangeIcon,
    Actions: (
      <div className="d-flex flex-wrap gap-20">
        <span className="lm-tag ">Data Sheet</span>
        <span className="lm-tag ">Service Note</span>
      </div>
    ),
  },
  {
    key: "1",
    Date: <span className="f-w-400">20/3/23</span>,
    Time: "9:30 AM",
    Technician: <span className="f-w-600">Mathew Johnson</span>,
    Status: lockLockedIconGrey,
    Actions: (
      <div className="d-flex flex-wrap gap-20">
        <span className="lm-tag ">Data Sheet</span>
        <span className="lm-tag ">Service Note</span>
      </div>
    ),
  },
  {
    key: "1",
    Date: <span className="f-w-400">20/3/23</span>,
    Time: "9:30 AM",
    Technician: <span className="f-w-600">Adam Lambert</span>,
    Status: locklockedOrangeIcon,
    Actions: (
      <div className="d-flex flex-wrap gap-20">
        <span className="lm-tag ">Data Sheet</span>
        <span className="lm-tag ">Service Note</span>
      </div>
    ),
  },
  {
    key: "1",
    Date: <span className="f-w-400">20/3/23</span>,
    Time: "9:30 AM",
    Technician: <span className="f-w-600">Mathew Johnson</span>,
    Status: lockLockedIconGrey,
    Actions: (
      <div className="d-flex flex-wrap gap-20">
        <span className="lm-tag ">Data Sheet</span>
        <span className="lm-tag ">Service Note</span>
      </div>
    ),
  },
  {
    key: "1",
    Date: <span className="f-w-400">20/3/23</span>,
    Time: "9:30 AM",
    Technician: <span className="f-w-600">Adam Lambert</span>,
    Status: locklockedOrangeIcon,
    Actions: (
      <div className="d-flex flex-wrap gap-20">
        <span className="lm-tag ">Data Sheet</span>
        <span className="lm-tag ">Service Note</span>
      </div>
    ),
  },
  {
    key: "1",
    Date: <span className="f-w-400">20/3/23</span>,
    Time: "9:30 AM",
    Technician: <span className="f-w-600">Mathew Johnson</span>,
    Status: lockLockedIconGrey,
    Actions: (
      <div className="d-flex flex-wrap gap-20">
        <span className="lm-tag ">Data Sheet</span>
        <span className="lm-tag ">Service Note</span>
      </div>
    ),
  },
  {
    key: "1",
    Date: <span className="f-w-400">20/3/23</span>,
    Time: "9:30 AM",
    Technician: <span className="f-w-600">Adam Lambert</span>,
    Status: locklockedOrangeIcon,
    Actions: (
      <div className="d-flex flex-wrap gap-20">
        <span className="lm-tag ">Data Sheet</span>
        <span className="lm-tag ">Service Note</span>
      </div>
    ),
  },
  {
    key: "1",
    Date: <span className="f-w-400">20/3/23</span>,
    Time: "9:30 AM",
    Technician: <span className="f-w-600">Mathew Johnson</span>,
    Status: lockLockedIconGrey,
    Actions: (
      <div className="d-flex flex-wrap gap-20">
        <span className="lm-tag ">Data Sheet</span>
        <span className="lm-tag ">Service Note</span>
      </div>
    ),
  },
  {
    key: "1",
    Date: <span className="f-w-400">20/3/23</span>,
    Time: "9:30 AM",
    Technician: <span className="f-w-600">Adam Lambert</span>,
    Status: locklockedOrangeIcon,
    Actions: (
      <div className="d-flex flex-wrap gap-20">
        <span className="lm-tag ">Data Sheet</span>
        <span className="lm-tag ">Service Note</span>
      </div>
    ),
  },
];
export const TechDailySessionsClientsTD = ({ data }) => {
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Adding 1 because months are zero-based
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
  }
  function convertToAMPM(utcDateString) {
    const utcDate = new Date(utcDateString);

    const hours = utcDate.getUTCHours();
    const minutes = utcDate.getUTCMinutes();
    let hours12 = hours % 12;
    hours12 = hours12 === 0 ? 12 : hours12;
    const period = hours < 12 ? "AM" : "PM";
    const formattedTime = `${hours12}:${minutes
      .toString()
      .padStart(2, "0")} ${period}`;

    return formattedTime;
  }

  const tableData = data?.map((row) => {
    const formattedTime = convertToAMPM(row?.start_time);
    return {
      key: row?.id || Math.random(),
      name: row?.client,
      Date: <span className="f-w-400">{formatDate(row?.schedule_date)}</span>,
      Time: row?.start_time,
      Status:
        row?.service_note_status == "approved"
          ? lockLockedIconGrey
          : unlockOrange,
    };
  });
  return tableData;
};

export const DirectorDailySessionsClientsTD = ({ data }) => {
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Adding 1 because months are zero-based
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
  }
  const tableData = data?.map((row) => {
    return {
      key: row?.id || Math.random(),
      name: row?.client,
      Technician: row?.technician,
      Date: <span className="f-w-400">{formatDate(row?.start_time)}</span>,
      Status: row?.is_completed ? unlockOrange : lockLockedIconGrey,
    };
  });
  return tableData;
};

export const TechDailySessionsClientsTDSession = ({ data }) => {
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Adding 1 because months are zero-based
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
  }
  function convertToAMPM(utcDateString) {
    const utcDate = new Date(utcDateString);

    const hours = utcDate.getUTCHours();
    const minutes = utcDate.getUTCMinutes();
    let hours12 = hours % 12;
    hours12 = hours12 === 0 ? 12 : hours12;
    const period = hours < 12 ? "AM" : "PM";
    const formattedTime = `${hours12}:${minutes
      .toString()
      .padStart(2, "0")} ${period}`;

    return formattedTime;
  }

  const tableData = data?.map((row) => {
    const formattedTime = convertToAMPM(row?.start_time);
    return {
      key: row?.id || Math.random(),
      name: row?.client_name,
      Date: <span className="f-w-400">{formatDate(row?.start_time)}</span>,
      Time: formattedTime,
      Status:
        row?.service_note_status == "approved"
          ? lockLockedIconGrey
          : unlockOrange,
    };
  });
  return tableData;
};
export const TechDailySessionsClientsTDSessionDir = ({ data }) => {
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Adding 1 because months are zero-based
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
  }

  const tableData = data?.map((row) => {
    return {
      key: row?.id || Math.random(),
      name: (
        <div className="d-flex flex-column cursor-simple">
          <span>{row.client_name}</span>
          <span className="f-12 lh-16 f-w-400 clr-light-grey-dim">
            {row.email}
          </span>
        </div>
      ),
      Date: <span className="f-w-400">{formatDate(row?.start_time)}</span>,
      Time: row?.start_time,
      Status: row?.status === "pending" ? lockLockedIconGrey : unlockOrange,
      Technician: row.technician_name,
    };
  });
  return tableData;
};
export const AllClientsSessionsTableData = [
  {
    key: Math.random(),
    Email: "emily.joe@exasdadample.com",
    Name: "Emily Joe",
    name: (
      <div className="d-flex flex-column">
        <span>Emily Joe</span>
        <span className="f-12 lh-16 f-w-400 clr-light-grey-dim">
          emily.joe@example.com
        </span>
      </div>
    ),
    Technician: "Adam lambert",
    Goals: "Accepting No",
    Date: "20/3/23",
    Status: <span className="cursor-pointer">{StatusCompletedIcon}</span>,
  },
  {
    key: Math.random(),
    Email: "emily.joe@example.com",
    Name: "Mary Sue",
    name: (
      <div className="d-flex flex-column">
        <span>Mary Sue</span>
        <span className="f-12 lh-16 f-w-400 clr-light-grey-dim">
          MarySue@example.com
        </span>
      </div>
    ),
    Technician: "Adam lambert",
    Goals: "Accepting No",
    Date: "20/3/23",
    Status: <span className="cursor-pointer">{StatusPendingIcon}</span>,
  },
  {
    key: Math.random(),
    Email: "emily.joe@example.com",
    Name: "Emily Joe",
    name: (
      <div className="d-flex flex-column">
        <span>Emily Joe</span>
        <span className="f-12 lh-16 f-w-400 clr-light-grey-dim">
          emily.joe@example.com
        </span>
      </div>
    ),
    Technician: "Adam lambert",
    Goals: "Accepting No",
    Date: "20/3/23",
    Status: <span className="cursor-pointer">{StatusCompletedIcon}</span>,
  },
  {
    key: Math.random(),
    Email: "emily.joe@example.com",
    Name: "Sam Jay",
    name: (
      <div className="d-flex flex-column">
        <span>Sam Jay</span>
        <span className="f-12 lh-16 f-w-400 clr-light-grey-dim">Sam Jay</span>
      </div>
    ),
    Technician: "Adam lambert",
    Goals: "Accepting No",
    Date: "20/3/23",
    Status: <span className="cursor-pointer">{StatusPendingIcon}</span>,
  },
  {
    key: Math.random(),
    Email: "emily.joe@example.com",
    Name: "Emily Joe",
    name: (
      <div className="d-flex flex-column">
        <span>Emily Joe</span>
        <span className="f-12 lh-16 f-w-400 clr-light-grey-dim">
          emily.joe@example.com
        </span>
      </div>
    ),
    Technician: "Adam lambert",
    Goals: "Accepting No",
    Date: "20/3/23",
    Status: <span className="cursor-pointer">{StatusCompletedIcon}</span>,
  },
  {
    key: Math.random(),
    Email: "emily.joe@example.com",
    Name: "Emily Joe",
    name: (
      <div className="d-flex flex-column">
        <span>Emily Joe</span>
        <span className="f-12 lh-16 f-w-400 clr-light-grey-dim">
          emily.joe@example.com
        </span>
      </div>
    ),
    Technician: "Adam lambert",
    Goals: "Accepting No",
    Date: "20/3/23",
    Status: <span className="cursor-pointer">{StatusCompletedIcon}</span>,
  },
  {
    key: Math.random(),
    Email: "emily.joe@example.com",
    Name: "Emily Joe",
    name: (
      <div className="d-flex flex-column">
        <span>Emily Joe</span>
        <span className="f-12 lh-16 f-w-400 clr-light-grey-dim">
          emily.joe@example.com
        </span>
      </div>
    ),
    Technician: "Adam lambert",
    Goals: "Accepting No",
    Date: "20/3/23",
    Status: <span className="cursor-pointer">{StatusCompletedIcon}</span>,
  },
  {
    key: Math.random(),
    Email: "emily.joe@example.com",
    Name: "Emily Joe",
    name: (
      <div className="d-flex flex-column">
        <span>Emily Joe</span>
        <span className="f-12 lh-16 f-w-400 clr-light-grey-dim">
          emily.joe@example.com
        </span>
      </div>
    ),
    Technician: "Adam lambert",
    Goals: "Accepting No",
    Date: "20/3/23",
    Status: <span className="cursor-pointer">{StatusCompletedIcon}</span>,
  },
  {
    key: Math.random(),
    Email: "emily.joe@example.com",
    Name: "Emily Joe",
    name: (
      <div className="d-flex flex-column">
        <span>Emily Joe</span>
        <span className="f-12 lh-16 f-w-400 clr-light-grey-dim">
          emily.joe@example.com
        </span>
      </div>
    ),
    Technician: "Adam lambert",
    Goals: "Accepting No",
    Date: "20/3/23",
    Status: <span className="cursor-pointer">{StatusCompletedIcon}</span>,
  },
  {
    key: Math.random(),
    Email: "emily.joe@example.com",
    Name: "Emily Joe",
    name: (
      <div className="d-flex flex-column">
        <span>Emily Joe</span>
        <span className="f-12 lh-16 f-w-400 clr-light-grey-dim">
          emily.joe@example.com
        </span>
      </div>
    ),
    Technician: "Adam lambert",
    Goals: "Accepting No",
    Date: "20/3/23",
    Status: <span className="cursor-pointer">{StatusCompletedIcon}</span>,
  },
  {
    key: Math.random(),
    Email: "emily.joe@example.com",
    Name: "Emily Joe",
    name: (
      <div className="d-flex flex-column">
        <span>Emily Joe</span>
        <span className="f-12 lh-16 f-w-400 clr-light-grey-dim">
          emily.joe@example.com
        </span>
      </div>
    ),
    Technician: "Adam lambert",
    Goals: "Accepting No",
    Date: "20/3/23",
    Status: <span className="cursor-pointer">{StatusCompletedIcon}</span>,
  },
];
export const TableHeadsPendingRequestsTech = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    render: (email) => (
      <span className="f-w-600 clr-grey-charchoal">{email} </span>
    ),
  },
  {
    title: "Date Joined",
    dataIndex: "DateJoined",
    key: "DateJoined",
  },
  {
    title: "Action",
    key: "Actions",
    dataIndex: "Actions",
  },
];
export const TableDataPendingRequestsTech = ({ data, setShowMailSent }) => {
  const tableData = data?.map((row) => {
    const words = row.full_name.split(" ").filter((word) => word.trim() !== "");
    let extractedLetters;
    if (words.length >= 2) {
      const firstLetterWord1 = words[0][0]?.toUpperCase();
      const firstLetterWord2 = words[1][0]?.toUpperCase();
      extractedLetters = firstLetterWord1 + firstLetterWord2;
    }
    return {
      id: row.id || row.slug,
      key: row.id || row.slug,
      Age: row.age || "-",
      slug: row.slug,
      form_completed: row.form_completed,
      Name: row.full_name,
      email: row.email,
      name: (
        <div
          className={`client-info-data cursor-pointer`}
          data-column-name="name"
        >
          {row?.profile_image ? (
            <img
              data-column-name="name"
              className=""
              alt="client-profile"
              src={row?.profile_image || "/src/assets/images/tkxel_logo.png"}
            ></img>
          ) : (
            <span
              data-column-name="name"
              className="name-show"
              alt="client-profile"
              // src={row?.profile_image || "/src/assets/images/tkxel_logo.png"}
            >
              {extractedLetters}
            </span>
          )}
          <span data-column-name="name">{row.full_name}</span>
        </div>
      ),
      DateJoined: row.enrolled_date,
      Actions: (
        <div className="d-flex gap-16 align-items-center">
          <span
            className="actions-tag actions-tag-orange  cursor-pointer"
            onClick={() => setShowMailSent(true)}
          >
            {forwardToInboxIcon}
          </span>
        </div>
      ),
    };
  });
  return tableData;
};
export const TableDataPendingRequests = ({
  data,
  setDeleteModal,
  setShowApproveModal,
  setShowTextEditor,
  setShowMailSent,
}) => {
  const navigate = useNavigate();
  const tableData = data?.map((row) => {
    const words = row.full_name.split(" ").filter((word) => word.trim() !== "");
    let extractedLetters;
    if (words.length >= 2) {
      const firstLetterWord1 = words[0][0]?.toUpperCase();
      const firstLetterWord2 = words[1][0]?.toUpperCase();
      extractedLetters = firstLetterWord1 + firstLetterWord2;
    }
    return {
      id: row.id || row.slug,
      key: row.id || row.slug,
      Age: row.age || "-",
      slug: row.slug,
      partially_filled: row.partially_filled ? row.partially_filled : false,
      form_completed: row.form_completed,
      Name: row.full_name,
      email: row.email,
      name: (
        <div
          className={"client-info-data cursor-none-click"}
          data-column-name="name"
        >
          {row?.profile_image ? (
            <img
              data-column-name="name"
              className=""
              alt="client-profile"
              src={row?.profile_image || "/src/assets/images/tkxel_logo.png"}
            ></img>
          ) : (
            <span
              data-column-name="name"
              className="name-show"
              alt="client-profile"
              // src={row?.profile_image || "/src/assets/images/tkxel_logo.png"}
            >
              {extractedLetters}
            </span>
          )}
          <span data-column-name="name">{row.full_name}</span>
        </div>
      ),
      DateJoined: row.enrolled_date,
      OnBoardingForm: row.partially_filled && (
        <div style={{ marginLeft: "40px" }}>
          <span
            className="actions-tag actions-tag-red  cursor-pointer"
            onClick={() => {
              navigate(`/clients/reviewForm/${row.slug}`);
            }}
          >
            {OnBoardingFormIcon}
          </span>
        </div>
      ),
      Actions: (
        <div className="d-flex gap-16 align-items-center">
          <span
            onClick={() => {
              setDeleteModal(true);
            }}
            className="actions-tag actions-tag-red  cursor-pointer"
          >
            {crossRed}
          </span>
          <span
            onClick={() => {
              row.form_completed && setShowApproveModal(true);
            }}
            // style={{ pointerEvents: !row.form_completed && "none" }}
            className={`actions-tag actions-tag-green ${
              !row.form_completed ? "cursor-none-click" : "cursor-pointer"
            } `}
          >
            {tickGreen}
          </span>
          <span
            onClick={() => {
              row.partially_filled && setShowTextEditor(true);
            }}
            className={`actions-tag actions-tag-yellow  ${
              !row.partially_filled ? "cursor-none-click" : "cursor-pointer"
            } `}
          >
            {addNotesIcon}
          </span>
          <span
            className="actions-tag actions-tag-orange  cursor-pointer"
            onClick={() => setShowMailSent(true)}
          >
            {forwardToInboxIcon}
          </span>
        </div>
      ),
    };
  });
  return tableData;
};
export const TableDataPendingRequestsDir = ({ data, setShowMailSent }) => {
  const tableData = data?.map((row) => {
    const words = row.full_name.split(" ").filter((word) => word.trim() !== "");
    let extractedLetters;
    if (words.length >= 2) {
      const firstLetterWord1 = words[0][0]?.toUpperCase();
      const firstLetterWord2 = words[1][0]?.toUpperCase();
      extractedLetters = firstLetterWord1 + firstLetterWord2;
    }
    return {
      id: row.id || row.slug,
      key: row.id || row.slug,
      Age: row.age || "-",
      slug: row.slug,
      partially_filled: row.partially_filled ? row.partially_filled : false,
      form_completed: row.form_completed,
      Name: row.full_name,
      email: row.email,
      name: (
        <div
          className={`client-info-data ${
            !row.partially_filled ? "cursor-none-click" : "cursor-pointer"
          }`}
          data-column-name="name"
        >
          {row?.profile_image ? (
            <img
              data-column-name="name"
              className=""
              alt="client-profile"
              src={row?.profile_image || "/src/assets/images/tkxel_logo.png"}
            ></img>
          ) : (
            <span
              data-column-name="name"
              className="name-show"
              alt="client-profile"
              // src={row?.profile_image || "/src/assets/images/tkxel_logo.png"}
            >
              {extractedLetters}
            </span>
          )}
          <span data-column-name="name">{row.full_name}</span>
        </div>
      ),
      DateJoined: row.enrolled_date,
      Actions: (
        <div className="d-flex gap-16 align-items-center">
          <span
            className="actions-tag actions-tag-orange  cursor-pointer"
            onClick={() => setShowMailSent(true)}
          >
            {forwardToInboxIcon}
          </span>
        </div>
      ),
    };
  });
  return tableData;
};
export const TableDataAllSessions = ({ data, setArchive }) => {
  const responseData = data?.map((row) => {
    const words = row.full_name.split(" ").filter((word) => word.trim() !== "");
    let extractedLetters;
    if (words.length >= 2) {
      const firstLetterWord1 = words[0][0]?.toUpperCase();
      const firstLetterWord2 = words[1][0]?.toUpperCase();
      extractedLetters = firstLetterWord1 + firstLetterWord2;
    }
    return {
      id: row.id || row.slug,
      email: row.email,
      key: row.id || row.slug,
      Age: row.age || "-",
      slug: row.slug,
      form_completed: row.form_completed,

      Name: row.full_name,
      name: (
        <div className="client-info-data" data-column-name="name">
          {row?.profile_image ? (
            <img
              data-column-name="name"
              className=""
              alt="client-profile"
              src={row?.profile_image || "/src/assets/images/tkxel_logo.png"}
            ></img>
          ) : (
            <span
              data-column-name="name"
              className="name-show"
              alt="client-profile"
              // src={row?.profile_image || "/src/assets/images/tkxel_logo.png"}
            >
              {extractedLetters}
            </span>
          )}
          <span data-column-name="name">{row.full_name}</span>
        </div>
      ),
      DateJoined: row.enrolled_date,
      Unitsleft: row.units || 0,
      // GoalsAssigned: "Accepting No",
      Archive: (
        <span onClick={() => setArchive(true)} className="cursor-pointer">
          <ImageView className={"img-fluid"} src={archiveIcon}></ImageView>
        </span>
      ),
    };
  });
  return responseData;
};

export const TableDataArchive = ({ data, setUnArchive }) => {
  const responseData = data?.map((row) => {
    const words = row.full_name.split(" ").filter((word) => word.trim() !== "");
    let extractedLetters;
    if (words.length >= 2) {
      const firstLetterWord1 = words[0][0]?.toUpperCase();
      const firstLetterWord2 = words[1][0]?.toUpperCase();
      extractedLetters = firstLetterWord1 + firstLetterWord2;
    }
    return {
      id: row.id || row.slug,
      email: row.email,
      key: row.id || row.slug,
      Age: row.age || "-",
      slug: row.slug,
      form_completed: row.form_completed,
      Name: row.full_name,
      name: (
        <div
          className="client-info-data disable-cursor"
          data-column-name="name"
        >
          {row?.profile_image ? (
            <img
              data-column-name="name"
              className=""
              alt="client-profile"
              src={row?.profile_image || "/src/assets/images/tkxel_logo.png"}
            ></img>
          ) : (
            <span
              data-column-name="name"
              className="name-show"
              alt="client-profile"
              // src={row?.profile_image || "/src/assets/images/tkxel_logo.png"}
            >
              {extractedLetters}
            </span>
          )}
          <span data-column-name="name">{row.full_name}</span>
        </div>
      ),
      DateJoined: row.enrolled_date,
      Unitsleft: row.Unitsleft || 0,
      // GoalsAssigned: "Accepting No",
      Unarchive: (
        <span
          onClick={() => setUnArchive(true)}
          className="actions-tag actions-tag-red  cursor-pointer"
        >
          {UnarchiveRed}
        </span>
      ),
    };
  });
  return responseData;
};
export const TableHeadsArchiveTech = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Experience",
    dataIndex: "experience",
    key: "experience",
    render: (experience) => (
      <span className="f-w-600 clr-grey-charchoal">{experience}</span>
    ),
  },
  {
    title: "Date Joined",
    dataIndex: "DateJoined",
    key: "DateJoined",
  },
  {
    title: "Last Clients",
    dataIndex: "last_client",
    key: "last_client",
  },
  {
    title: "Unarchive",
    key: "Unarchive",
    dataIndex: "Unarchive",
  },
];
const dumy = ["karmra", "bello", "leello"];
export const TableDataArchiveTech = ({ data, setUnArchive }) => {
  const responseData = data?.map((row) => {
    const words = row.full_name.split(" ").filter((word) => word.trim() !== "");
    let extractedLetters;
    if (words.length >= 2) {
      const firstLetterWord1 = words[0][0]?.toUpperCase();
      const firstLetterWord2 = words[1][0]?.toUpperCase();
      extractedLetters = firstLetterWord1 + firstLetterWord2;
    }
    return {
      id: row.id || row.slug,
      email: row.email,
      key: row.id || row.slug,
      Age: row.age || "-",
      slug: row.slug,
      form_completed: row.form_completed,
      Name: row.full_name,
      name: (
        <div
          className="client-info-data disable-cursor"
          data-column-name="name"
        >
          {row?.profile_image ? (
            <img
              data-column-name="name"
              className=""
              alt="client-profile"
              src={row?.profile_image || "/src/assets/images/tkxel_logo.png"}
            ></img>
          ) : (
            <span
              data-column-name="name"
              className="name-show"
              alt="client-profile"
              // src={row?.profile_image || "/src/assets/images/tkxel_logo.png"}
            >
              {extractedLetters}
            </span>
          )}
          <span data-column-name="name">{row.full_name}</span>
        </div>
      ),
      last_client: row?.last_clients ? (
        <div className="d-flex gap-10 align-items-center">
          <span className="tech-last-clients">
            {row?.last_clients ? row.last_clients[0] : "-"}
          </span>

          {row.last_clients.length > 1 && (
            <Tooltip
              overlayClassName="lm-table-tooltip"
              color={"#ffff"}
              placement="right"
              title={
                <section className="clr-dark-grey d-flex flex-column gap-4 p-12">
                  <ul>
                    {row.last_clients.slice(1).map((line, index) => (
                      <li
                        key={index}
                        className="f-12 lh-18 f-w-400 clr-grey-charchoal"
                      >
                        {line}
                      </li>
                    ))}
                  </ul>
                </section>
              }
            >
              <span className="additional-tech-clients">
                +{row.last_clients?.length - 1}
              </span>
            </Tooltip>
          )}
        </div>
      ) : (
        "-"
      ),
      // last_client: "Not Coming FROM BE",
      experience: row.experience || "-",
      DateJoined: row.enrolled_date,
      // GoalsAssigned: "Accepting No",
      Unarchive: (
        <span
          onClick={() => setUnArchive(true)}
          className="actions-tag actions-tag-red  cursor-pointer"
        >
          {UnarchiveRed}
        </span>
      ),
    };
  });
  return responseData;
};
export const TableHeadsClientSession = [
  {
    title: "Client",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Technician",
    dataIndex: "Technician",
    key: "Technician",
  },
  {
    title: "Goals",
    dataIndex: "Goals",
    key: "Goals",
  },
  {
    title: "Date",
    dataIndex: "Date",
    key: "Date",
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
  },
];
export const TableHeadsTechCompletedSession = [
  {
    title: "Client",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Date",
    dataIndex: "Date",
    key: "Date",
  },
  {
    title: "Time",
    dataIndex: "Time",
    key: "Time",
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
  },
];
export const TableHeadsTechCompletedSessionDir = [
  {
    title: "Client",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Technician",
    dataIndex: "Technician",
    key: "Technician",
  },
  {
    title: "Date",
    dataIndex: "Date",
    key: "Date",
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
  },
];
export const TableHeadsDirectorCompletedSession = [
  {
    title: "Client",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Technician",
    dataIndex: "Technician",
    key: "Technician",
  },
  {
    title: "Date",
    dataIndex: "Date",
    key: "Date",
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
  },
];
export const TableHeadsTechPendingSession = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Age",
    dataIndex: "Age",
    key: "Age",
  },
  {
    title: "Date",
    dataIndex: "Date",
    key: "Date",
  },
  {
    title: "Time",
    dataIndex: "Time",
    key: "Time",
  },
  {
    title: "",
    dataIndex: "Actions",
    key: "Actions",
  },
];
export const TableHeadsTechSingle = [
  {
    title: "Date",
    dataIndex: "Date",
    key: "Date",
  },
  {
    title: "Time",
    dataIndex: "Time",
    key: "Time",
  },
  {
    title: "Technician",
    dataIndex: "Technician",
    key: "Technician",
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
  },
  {
    title: "",
    dataIndex: "Actions",
    key: "Actions",
  },
];
export const TableHeadsTechSingleTech = [
  {
    title: "Date",
    dataIndex: "Date",
    key: "Date",
  },
  {
    title: "Time",
    dataIndex: "Time",
    key: "Time",
  },
  {
    title: "Client",
    dataIndex: "Technician",
    key: "Technician",
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
  },
  {
    title: "",
    dataIndex: "Actions",
    key: "Actions",
  },
];
export const TechDailySessionsClientsSingle = ({ data, setdashboardView }) => {
  const user = GetCurrentUser();
  const navigate = useNavigate();
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Adding 1 because months are zero-based
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
  }
  function convertToAMPM(utcDateString) {
    const utcDate = new Date(utcDateString);

    const hours = utcDate.getUTCHours();
    const minutes = utcDate.getUTCMinutes();
    let hours12 = hours % 12;
    hours12 = hours12 === 0 ? 12 : hours12;
    const period = hours < 12 ? "AM" : "PM";
    const formattedTime = `${hours12}:${minutes
      .toString()
      .padStart(2, "0")} ${period}`;

    return formattedTime;
  }

  const tableData = data?.map((row) => {
    const formattedTime = convertToAMPM(row?.start_time);
    return {
      key: row?.id || Math.random(),
      Technician: row?.technician_name,
      Date: <span className="f-w-400">{formatDate(row?.start_time)}</span>,
      Time: formattedTime,
      Status:
        row?.service_note_status == "approved"
          ? lockLockedIconGrey
          : unlockOrange,
      Actions: (
        <div className="d-flex justify-content-center gap-20 w-100 tocolumn">
          <div
            className="badge badge-service-note"
            style={{ cursor: "pointer" }}
            title="Check in"
            onClick={() => {
              if (user.role === "director") {
                navigate(
                  `/dashboard/checkin/${row?.data_sheet_id}/${row.client_slug}/${row.id}/${row.service_note_id}`
                );
              } else {
                navigate(
                  `/yourClients/checkin/${row?.data_sheet_id}/${row.client_slug}/${row.id}/${row.service_note_id}`,
                  { state: { yourClient: true } }
                );
              }

              setdashboardView("checkin");
            }}
          >
            {serviceNoteIconColored}
            <span>Check In</span>
          </div>
          <div
            className="badge badge-service-note"
            style={{ cursor: "pointer" }}
            title="Check out"
            onClick={() => {
              if (user.role === "director") {
                navigate(
                  `/dashboard/checkout/${row?.data_sheet_id}/${row.client_slug}/${row.id}/${row.service_note_id}`
                );
              } else {
                navigate(
                  `/yourClients/checkout/${row?.data_sheet_id}/${row.client_slug}/${row.id}/${row.service_note_id}`,
                  { state: { yourClient: true } }
                );
              }
              // navigate(
              //   `/techdashboard/checkout/${row?.data_sheet_id}/${row.client_slug}/${row.id}/${row.service_note_id}`,
              //   { state: { techDashboard: true } }
              // );
              setdashboardView("checkout");
            }}
          >
            {serviceNoteIconColored}
            <span>Check out</span>
          </div>
          <div
            className="badge badge-service-note"
            style={{ cursor: "pointer" }}
            title="Data Sheet"
            onClick={() => {
              // eslint-disable-next-line no-unused-expressions
              if (user.role === "director") {
                navigate(
                  `/dashboard/datasheet/${row?.data_sheet_id}/${row.client_slug}/${row.id}/${row.service_note_id}`,
                  { state: { yourClient: true } }
                );
              } else {
                navigate(
                  `/yourClients/datasheet/${row?.data_sheet_id}/${row.client_slug}/${row.id}/${row.service_note_id}`,
                  { state: { yourClient: true } }
                );
              }
              setdashboardView("datasheet");
            }}
          >
            <DataSheetIcon />
            <span>Data Sheet</span>
          </div>
          <div
            className="badge badge-service-note"
            style={{ cursor: "pointer" }}
            title="Service Note"
            onClick={() => {
              // eslint-disable-next-line no-unused-expressions
              if (user?.role === "director") {
                navigate(
                  `/dashboard/serviceNote/${row.id}/${row.service_note_id}/${row.data_sheet_id}`,
                  {
                    state: { yourClient: true },
                  }
                );
              } else {
                navigate(
                  `/yourClients/serviceNote/${row.id}/${row.service_note_id}/${row.data_sheet_id}`,
                  {
                    state: { yourClient: true },
                  }
                );
              }
              setdashboardView("serviceNote");
            }}
          >
            {serviceNoteIconColored}
            <span>Service Note</span>
          </div>
        </div>
      ),
    };
  });
  return tableData;
};
export const TechDailySessionsClientsSingleTech = ({
  data,
  setdashboardView,
}) => {
  const user = GetCurrentUser();
  const navigate = useNavigate();
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Adding 1 because months are zero-based
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
  }
  function convertToAMPM(utcDateString) {
    const utcDate = new Date(utcDateString);

    const hours = utcDate.getUTCHours();
    const minutes = utcDate.getUTCMinutes();
    let hours12 = hours % 12;
    hours12 = hours12 === 0 ? 12 : hours12;
    const period = hours < 12 ? "AM" : "PM";
    const formattedTime = `${hours12}:${minutes
      .toString()
      .padStart(2, "0")} ${period}`;

    return formattedTime;
  }
  const tableData = data?.map((row) => {
    const formattedTime = convertToAMPM(row?.start_time);
    return {
      key: row?.id || Math.random(),
      Technician: row?.client_name,
      Date: <span className="f-w-400">{formatDate(row?.start_time)}</span>,
      Time: formattedTime,
      Status:
        row?.service_note_status == "approved"
          ? lockLockedIconGrey
          : unlockOrange,
      Actions: (
        <div className="d-flex justify-content-center gap-20 w-100 tocolumn">
          <div
            className="badge badge-service-note"
            style={{ cursor: "pointer" }}
            title="Check in"
            onClick={() => {
              if (user.role === "director") {
                navigate(
                  `/dashboardT/checkin/${row?.data_sheet_id}/${row.client_slug}/${row.id}/${row.service_note_id}`
                );
              } else {
                navigate(
                  `/yourClients/checkin/${row?.data_sheet_id}/${row.client_slug}/${row.id}/${row.service_note_id}`,
                  { state: { yourClient: true } }
                );
              }

              setdashboardView("checkin");
            }}
          >
            {serviceNoteIconColored}
            <span>Check In</span>
          </div>
          <div
            className="badge badge-service-note"
            style={{ cursor: "pointer" }}
            title="Check out"
            onClick={() => {
              if (user.role === "director") {
                navigate(
                  `/dashboardT/checkout/${row?.data_sheet_id}/${row.client_slug}/${row.id}/${row.service_note_id}`
                );
              } else {
                navigate(
                  `/yourClients/checkout/${row?.data_sheet_id}/${row.client_slug}/${row.id}/${row.service_note_id}`,
                  { state: { yourClient: true } }
                );
              }
              // navigate(
              //   `/techdashboard/checkout/${row?.data_sheet_id}/${row.client_slug}/${row.id}/${row.service_note_id}`,
              //   { state: { techDashboard: true } }
              // );
              setdashboardView("checkout");
            }}
          >
            {serviceNoteIconColored}
            <span>Check out</span>
          </div>
          <div
            className="badge badge-service-note"
            style={{ cursor: "pointer" }}
            title="Data Sheet"
            onClick={() => {
              // eslint-disable-next-line no-unused-expressions
              if (user.role === "director") {
                navigate(
                  `/dashboardT/datasheet/${row?.data_sheet_id}/${row.client_slug}/${row.id}/${row.service_note_id}`,
                  { state: { yourClient: true } }
                );
              } else {
                navigate(
                  `/yourClients/datasheet/${row?.data_sheet_id}/${row.client_slug}/${row.id}/${row.service_note_id}`,
                  { state: { yourClient: true } }
                );
              }
              setdashboardView("datasheet");
            }}
          >
            <DataSheetIcon />
            <span>Data Sheet</span>
          </div>
          <div
            className="badge badge-service-note"
            style={{ cursor: "pointer" }}
            title="Service Note"
            onClick={() => {
              // eslint-disable-next-line no-unused-expressions
              if (user?.role === "director") {
                navigate(
                  `/dashboardT/serviceNote/${row.id}/${row.service_note_id}/${row.data_sheet_id}`,
                  {
                    state: { yourClient: true },
                  }
                );
              } else {
                navigate(
                  `/yourClients/serviceNote/${row.id}/${row.service_note_id}/${row.data_sheet_id}`,
                  {
                    state: { yourClient: true },
                  }
                );
              }
              setdashboardView("serviceNote");
            }}
          >
            {serviceNoteIconColored}
            <span>Service Note</span>
          </div>
        </div>
      ),
    };
  });
  return tableData;
};
function formatDate(dateString) {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.getMonth() + 1; // Adding 1 because months are zero-based
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
}
export const TableDataTechYourClients = ({
  data,
  setdashboardView,
  single,
}) => {
  const navigate = useNavigate();
  // console.log(data, "your client data");

  const responseData = data?.map((row) => {
    const words = row?.full_name
      .split(" ")
      .filter((word) => word.trim() !== "");
    let extractedLetters;
    if (words.length >= 2) {
      const firstLetterWord1 = words[0][0]?.toUpperCase();
      const firstLetterWord2 = words[1][0]?.toUpperCase();
      extractedLetters = firstLetterWord1 + firstLetterWord2;
    }
    return {
      id: row?.id || row.slug,
      key: row?.id || row.slug,
      slug: row?.slug,
      // Name: row?.name,
      Name: (
        <div
          className="client-info-data disable-cursor"
          data-column-name="name"
          style={{ cursor: single && "pointer" }}
          onClick={() => {
            if (single) {
              setdashboardView("yourclientsingle");
              navigate(`/yourClients/${row.slug}`, {
                state: { name: row.full_name },
              });
            }
          }}
        >
          {row?.profile_image ? (
            <img
              data-column-name="name"
              className=""
              alt="client-profile"
              src={row?.profile_image || "/src/assets/images/tkxel_logo.png"}
            ></img>
          ) : (
            <span
              data-column-name="name"
              className="name-show"
              alt="client-profile"
              // src={row?.profile_image || "/src/assets/images/tkxel_logo.png"}
            >
              {extractedLetters}
            </span>
          )}
          <span data-column-name="name">{row.full_name}</span>
        </div>
      ),
      Age: row.age || "-",
      PhoneNo: row?.phone_no || "-",
      Address: row.address || "New York",
      Actions: (
        <button
          style={{ border: "none" }}
          onClick={() => {
            setdashboardView("clientGoals");
            navigate(`/yourClients/goals/${row.slug}`);
          }}
        >
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="31.5"
              y="31.5"
              width="31"
              height="31"
              rx="5.5"
              transform="rotate(180 31.5 31.5)"
              fill="#FFF6EE"
            />
            <mask
              id="mask0_2460_19630"
              // style="mask-type:alpha"
              maskUnits="userSpaceOnUse"
              x="6"
              y="6"
              width="20"
              height="20"
            >
              <rect x="6" y="6" width="20" height="20" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_2460_19630)">
              <path
                d="M16 19.334C17.0417 19.334 17.9271 18.9694 18.6563 18.2402C19.3855 17.5111 19.75 16.6257 19.75 15.584C19.75 14.5423 19.3855 13.6569 18.6563 12.9277C17.9271 12.1986 17.0417 11.834 16 11.834C14.9584 11.834 14.073 12.1986 13.3438 12.9277C12.6146 13.6569 12.25 14.5423 12.25 15.584C12.25 16.6257 12.6146 17.5111 13.3438 18.2402C14.073 18.9694 14.9584 19.334 16 19.334ZM16 17.834C15.375 17.834 14.8438 17.6152 14.4063 17.1777C13.9688 16.7402 13.75 16.209 13.75 15.584C13.75 14.959 13.9688 14.4277 14.4063 13.9902C14.8438 13.5527 15.375 13.334 16 13.334C16.625 13.334 17.1563 13.5527 17.5938 13.9902C18.0313 14.4277 18.25 14.959 18.25 15.584C18.25 16.209 18.0313 16.7402 17.5938 17.1777C17.1563 17.6152 16.625 17.834 16 17.834ZM16 21.834C13.9723 21.834 12.125 21.268 10.4584 20.1361C8.79171 19.0041 7.58337 17.4868 6.83337 15.584C7.58337 13.6812 8.79171 12.1638 10.4584 11.0319C12.125 9.89996 13.9723 9.33398 16 9.33398C18.0278 9.33398 19.875 9.89996 21.5417 11.0319C23.2084 12.1638 24.4167 13.6812 25.1667 15.584C24.4167 17.4868 23.2084 19.0041 21.5417 20.1361C19.875 21.268 18.0278 21.834 16 21.834ZM16 20.1673C17.5695 20.1673 19.0105 19.7541 20.323 18.9277C21.6355 18.1013 22.6389 16.9868 23.3334 15.584C22.6389 14.1812 21.6355 13.0666 20.323 12.2402C19.0105 11.4138 17.5695 11.0007 16 11.0007C14.4306 11.0007 12.9896 11.4138 11.6771 12.2402C10.3646 13.0666 9.36115 14.1812 8.66671 15.584C9.36115 16.9868 10.3646 18.1013 11.6771 18.9277C12.9896 19.7541 14.4306 20.1673 16 20.1673Z"
                fill="#F6891F"
              />
            </g>
            <rect
              x="31.5"
              y="31.5"
              width="31"
              height="31"
              rx="5.5"
              transform="rotate(180 31.5 31.5)"
              stroke="#F6891F"
            />
          </svg>
        </button>
      ),
    };
  });
  return responseData;
};
export const TableDataTechPendingSession = ({ data, setdashboardView }) => {
  const navigate = useNavigate();
  function convertToAMPM(utcDateString) {
    const utcDate = new Date(utcDateString);

    const hours = utcDate.getUTCHours();
    const minutes = utcDate.getUTCMinutes();
    let hours12 = hours % 12;
    hours12 = hours12 === 0 ? 12 : hours12;
    const period = hours < 12 ? "AM" : "PM";
    const formattedTime = `${hours12}:${minutes
      .toString()
      .padStart(2, "0")} ${period}`;

    return formattedTime;
  }

  const responseData = data?.map((row) => {
    const words = row.client_name
      .split(" ")
      .filter((word) => word.trim() !== "");
    let extractedLetters;
    if (words.length >= 2) {
      const firstLetterWord1 = words[0][0]?.toUpperCase();
      const firstLetterWord2 = words[1][0]?.toUpperCase();
      extractedLetters = firstLetterWord1 + firstLetterWord2;
    }
    const formattedTime = convertToAMPM(row?.start_time);
    return {
      id: row?.id || row.slug,
      key: row?.id || row.slug,
      slug: row?.slug,
      Name: row.client_name,
      name: (
        <div
          className="client-info-data disable-cursor"
          data-column-name="name"
        >
          {row?.profile_image ? (
            <img
              data-column-name="name"
              className=""
              alt="client-profile"
              src={row?.profile_image || "/src/assets/images/tkxel_logo.png"}
            ></img>
          ) : (
            <span
              data-column-name="name"
              className="name-show"
              alt="client-profile"
              // src={row?.profile_image || "/src/assets/images/tkxel_logo.png"}
            >
              {extractedLetters}
            </span>
          )}
          <span data-column-name="name">{row.client_name}</span>
        </div>
      ),
      Age: row.age || "-",
      Date: <span className="f-w-400">{formatDate(row?.start_time)}</span>,
      Time: formattedTime,
      Actions: (
        <div className="d-flex justify-content-center gap-20 w-100 tocolumn">
          <div
            className="badge badge-service-note"
            style={{ cursor: "pointer" }}
            title="Check in"
            onClick={() => {
              navigate(
                `/techdashboard/checkin/${row?.data_sheet_id}/${row.client_slug}/${row.id}/${row.service_note_id}`,
                { state: { techDashboard: true } }
              );
              setdashboardView("checkin");
            }}
          >
            {serviceNoteIconColored}
            <span>Check in</span>
          </div>
          <div
            className="badge badge-service-note"
            style={{ cursor: "pointer" }}
            title="Data Sheet"
            onClick={() => {
              navigate(
                `/techdashboard/checkout/${row?.data_sheet_id}/${row.client_slug}/${row.id}/${row.service_note_id}`,
                { state: { techDashboard: true } }
              );
              setdashboardView("checkout");
            }}
          >
            {serviceNoteIconColored}
            <span>Check out</span>
          </div>
          <div
            className="badge badge-service-note"
            style={{ cursor: "pointer" }}
            title="Check out"
            onClick={() => {
              navigate(
                `/techdashboard/datasheet/${row?.data_sheet_id}/${row.client_slug}/${row.id}`,
                { state: { techDashboard: true } }
              );
              setdashboardView("datasheet");
            }}
          >
            <DataSheetIcon />
            <span>Data Sheet</span>
          </div>
          <div
            className="badge badge-service-note"
            // eslint-disable-next-line no-unused-expressions
            style={{ cursor: "pointer" }}
            title="Service Note"
            onClick={() => {
              navigate(
                `/techdashboard/serviceNote/${row.id}/${row.service_note_id}/${row.data_sheet_id}`,
                {
                  state: { techDashboard: true },
                }
              );
              setdashboardView("serviceNote");
            }}
          >
            {serviceNoteIconColored}
            <span>Service Note</span>
          </div>
        </div>
      ),
    };
  });
  return responseData;
};

const locationENUM = {
  in_clinic: "In Clinic",
  in_home: "Home",
};

const formatTime = (value) => {
  const date = new Date(value);
  const options = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    timeZone: "UTC", // Specify the time zone as UTC
  };
  const time = date.toLocaleString("en-US", options);
  return time;
};

export const UpcomingSessionsTableData = ({
  data,
  setDeleteModal,
  setAppointEditModal,
  setAppointmentCancelModal,
  setCancelReasonModal,
  setCancellationReason,
  settingOptionsEnabled,
  setGlobalSettingModal,
  setAppointmentID,
}) => {
  const responseData = data?.map((row) => {
    return {
      id: row.id,
      key: row.key,
      Name: row.client_name,
      name: (
        <div className="client-info-data client-info-data-upcoming">
          <span
            data-column-name="name"
            className="rounded-avatar-letters rounded-avatar-xsm name-show"
            alt="client-profile"
            style={{ fontSize: "12px", width: "35px", height: "35px" }}
          >
            {getLetter(row.client_name)}
          </span>
          <span>{row?.client_name}</span>
        </div>
      ),

      Technician: row?.technician_name,

      Details: (
        <div className="d-flex gap-20">
          <div className="badge badge-upcoming">
            {locationIconBlack}
            <span>{locationENUM[row?.location]}</span>
          </div>
          <div className="badge badge-upcoming">
            {alarmIconBlack}
            <span>{`${formatTime(row?.start_time)} - ${formatTime(
              row.end_time
            )}`}</span>
          </div>
          <div className="badge badge-upcoming">
            <span
              style={{
                color: ["rejected", "canceled", "archived"].includes(
                  row?.status
                )
                  ? "red"
                  : "green",
              }}
            >
              {row?.status}
            </span>
          </div>
        </div>
      ),

      Delete: settingOptionsEnabled && (
        <div style={{ display: "flex", gap: "5px" }}>
          <>
            {(row?.status === "scheduled" || row.status === "planned") && (
              <>
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    setGlobalSettingModal(true);
                    setAppointmentID(row?.id);
                  }}
                >
                  <img
                    className=""
                    alt="client-profile"
                    src={globalSettingIcon}
                  ></img>
                </span>
                <span
                  className="cursor-pointer"
                  onClick={() => setDeleteModal(true)}
                >
                  <img
                    className=""
                    alt="client-profile"
                    src={deleteTrashRed}
                  ></img>
                </span>

                {row?.status == "scheduled" && (
                  <span
                    className="cursor-pointer"
                    style={{
                      background: "aliceblue",
                      borderRadius: "2px",
                      padding: "6px",
                    }}
                    onClick={() => setAppointEditModal(true)}
                  >
                    <img className="" alt="edit" src={editicon}></img>
                  </span>
                )}

                <span
                  className="cursor-pointer"
                  onClick={() => setAppointmentCancelModal(true)}
                >
                  <img className="" alt="client-profile" src={crossIcon}></img>
                </span>
              </>
            )}

            {row?.status === "canceled" && (
              <>
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    setCancelReasonModal(true);
                    setCancellationReason(row?.meta?.cancellation_reason);
                  }}
                >
                  <img className="" alt="client-profile" src={infoIcon}></img>
                </span>
              </>
            )}
          </>
        </div>
      ),
    };
  });
  return responseData;
};

export const AwaitingTableData = ({ data, navigate, setdashboardView }) => {
  const responseData = data?.map((row) => {
    return {
      id: row.id,
      key: row.id,
      name: (
        <div className="client-info-data client-info-data-upcoming">
          <span
            data-column-name="name"
            className="rounded-avatar-letters rounded-avatar-xsm name-show"
            alt="client-profile"
            style={{ fontSize: "12px", width: "35px", height: "35px" }}
          >
            {getLetter(row.client_name)}
          </span>
          <span>{row.client_name}</span>
        </div>
      ),
      ServiceNote: (
        <div
          style={{ cursor: "pointer" }}
          className="badge badge-service-note"
          onClick={() => {
            navigate(
              `/appointments/serviceNote/${row.id}/${row.service_note_id}/${row.data_sheet_id}`
            );

            setdashboardView("serviceNote");
          }}
        >
          {serviceNoteIconColored}
          <span>Service Note</span>
        </div>
      ),
      Technician: row.technician_name,
    };
  });
  return responseData;
};

export const ClientBillingData = ({
  data,
  setBillingId,
  setPayload,
  setShowEditHistoryModal,
  setOpenEditConfirmationModal,
  setBillingData,
}) => {
  const CodeOptions = [
    { value: "97151", key: "97151" },
    { value: "97152", key: "97152" },
    { value: "97153", key: "97153" },
    { value: "97154", key: "97154" },
    { value: "97155", key: "97155" },
    { value: "97156", key: "97156" },
    { value: "97157", key: "97157" },
  ];

  const convertToAmPm = (timeString) => {
    const time = moment(timeString, "HH:mm:ss");
    const formattedTime = time.format("h:mm A");
    return formattedTime;
  };

  const handleTimeUpdates = (
    billingID,
    value,
    serviceDate,
    key,
    originalValue,
    setBillingData
  ) => {
    const serviceDateTime = new Date(serviceDate.replace(/-/g, "/"));
    const hours = value.$H;
    const minutes = value.$m;
    serviceDateTime.setHours(hours, minutes);
    let updatedtimeString = serviceDateTime
      .toTimeString()
      .replace(/ GMT[+-]\d{4}.*$/, "");
    let updatedDateString = serviceDateTime.toDateString();
    let updatedDateTime = updatedDateString + " " + updatedtimeString;

    const updateObject = { [key]: updatedDateTime };
    setBillingId(billingID);

    setPayload({
      params: updateObject,
      originalValue: convertToAmPm(originalValue),
      updatedValue: convertToAmPm(updatedtimeString),
    });
    setOpenEditConfirmationModal(true);

    setBillingData((prev) => ({
      ...prev,
      data: prev.data.map((item) => {
        if (Number(item.id) === Number(billingID)) {
          return { ...item, [key]: updatedtimeString };
        }
        return item;
      }),
    }));
  };
  const responseData = data?.map((row) => {
    return {
      id: row.id,
      key: row.id,
      dateOfService: (
        <div className="billing-table-rows">
          <span>{row.date_of_service}</span>
        </div>
      ),
      clientId: row.client_id,
      nickName: row.client_nick_name,
      providerName: row.provider_name,
      startTime: (
        <CustomTimePicker
          defaultTime={row.appointment_start_time}
          column={"appointment_start_time"}
          handleValue={(value) => {
            handleTimeUpdates(
              row.id,
              value,
              row.date_of_service,
              "appointment_start_time",
              row.appointment_start_time,
              setBillingData
            );
          }}
        />
      ),
      endTime: (
        <CustomTimePicker
          defaultTime={row.appointment_end_time}
          handleValue={(value) =>
            handleTimeUpdates(
              row.id,
              value,
              row.date_of_service,
              "appointment_end_time",
              row.appointment_end_time,
              setBillingData
            )
          }
        />
      ),

      totalUnits: (
        <div className="billing-table-rows">
          <span>{row.total_units}</span>
        </div>
      ),
      billingCode: row.billing_code,
      billingCode: (
        <CustomSelect
          className={"billing_appo_custom_selector"}
          simple={true}
          options={CodeOptions}
          handleValue={(value) => {
            setBillingId(row.id);
            setPayload({
              params: { billing_code: value },
              originalValue: row.billing_code || "- - -",
              updatedValue: value,
            });
            setOpenEditConfirmationModal(true);
            setBillingData((prev) => ({
              ...prev,
              data: prev.data.map((item) => {
                if (item.id === row.id) {
                  return { ...item, billingCode: value };
                }
                return item;
              }),
            }));
          }}
          name={row.billing_code}
          customValue={true}
        />
      ),
      modifierCode: (
        <CustomSelect
          className={"billing_appo_custom_selector"}
          simple={true}
          options={[
            { value: "HO", key: "ho" },
            { value: "TECH", key: "tech" },
          ]}
          handleValue={(value) => {
            setBillingId(row.id);
            setPayload({
              params: { modifier_code: value },
              originalValue: row.modifier_code.toUpperCase(),
              updatedValue: value.toUpperCase(),
            });
            setBillingData((prev) => ({
              ...prev,
              data: prev.data.map((item) => {
                if (item.id === row.id) {
                  return { ...item, modifier_code: value };
                }
                return item;
              }),
            }));
            setOpenEditConfirmationModal(true);
          }}
          name={row.modifier_code ? row.modifier_code.toUpperCase() : ""}
          // time={false}
          // objectives={false}
          customValue={true}
          // fieldValue={row.modifier_code ? row.modifier_code.toUpperCase() : ""}
          // parentValue={row.modifier_code ? row.modifier_code.toUpperCase() : ""}
        />
      ),
      totalAmount: row.total_amount,
      actions: (
        <span
          onClick={() => {
            setShowEditHistoryModal(true);
            setBillingId(row.id);
          }}
          className="actions-tag actions-tag-red  cursor-pointer"
          style={{ width: "40px", height: "40px" }}
        >
          {EyeIcon}
        </span>
      ),
    };
  });
  return responseData;
};

export const ProgressReportMasteredData = ({ data }) => {
  const responseData = data?.map((row) => {
    return {
      id: row.id,
      key: row.id,
      GoalName: row.goal_title,
      MasteryDate: row.mastered_date,
      MasteryTime: row.mastered_time,
    };
  });

  return responseData;
};

export const ProgressReportInProgressData = ({ data }) => {
  const responseData = data?.map((row) => {
    return {
      id: row.id,
      key: row.id,
      GoalName: row.goal_title,
      StartDate: row.first_working_date,
      HighestScore: row.highest_score,
      LowestScore: row.lowest_score,
    };
  });

  return responseData;
};

export const ProgressReportAssignedData = ({ data }) => {
  const responseData = data?.map((row) => {
    return {
      id: row.id,
      key: row.id,
      GoalName: row.goal_title,
      AssignedDate: row.start_date,
      Level: row.level,
    };
  });

  return responseData;
};

export const TableHeadsUpcomingSession = [
  {
    title: "",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "",
    dataIndex: "Technician",
    key: "Technician",
  },
  {
    title: "",
    dataIndex: "Details",
    key: "Details",
  },
  // {
  //   title: "",
  //   dataIndex: "ServiceNote",
  //   key: "ServiceNote",
  // },
  {
    title: "",
    dataIndex: "Delete",
    key: "Delete",
  },
];

export const TableHeadsAwaiting = [
  {
    title: "",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "",
    dataIndex: "Technician",
    key: "Technician",
  },

  {
    title: "",
    dataIndex: "ServiceNote",
    key: "ServiceNote",
  },
];

export const TableDataAssignedGoals = ({
  data,
  handleChangeStatus,
  setArchiveGoal,
  setGoalUserMappingId,
}) => {
  const tableData = data?.map((row) => {
    const words = row?.description?.split(".");
    const dateString = row?.goal_user_mappings[0]?.start_date;
    const formattedDate = dateString?.split("T")[0];
    const dateAssigned = formattedDate?.replace(/-/g, "/");
    return {
      key: row?.id,
      RefNo: row?.reference_no,
      goalTitle: row?.title,
      GoalTitle: (
        <div className="d-flex gap-10">
          <span>{row?.title}</span>

          {row?.description && (
            <Tooltip
              overlayClassName="lm-table-tooltip"
              color={"#ffff"}
              placement="bottom"
              style={{ minWidth: "15px" }}
              title={
                <section className="clr-dark-grey d-flex flex-column gap-4 p-12">
                  <h5 className="f-13 lh-18 f-w-600 clr-dark-grey">
                    {row.title}
                  </h5>
                  <ul>
                    {words.map((line) => {
                      return (
                        line.length > 0 && (
                          <li className="f-12 lh-18 f-w-400 clr-grey-charchoal">
                            {line}
                          </li>
                        )
                      );
                    })}
                  </ul>
                </section>
              }
            >
              <span style={{ minWidth: "15px !important" }}>
                {infoIconYellow}
              </span>
            </Tooltip>
          )}
        </div>
      ),
      Progress: (
        <span
          className={`cursor-none-click technicians-client-status ${
            row?.goal_user_mappings[0]?.level == "in_progress"
              ? "in-progress"
              : "mastered"
          }`}
        >
          {row?.goal_user_mappings[0]?.level?.replace("_", " ")}
        </span>
      ),

      DateAssigned: dateAssigned,
      Actions: [
        <span
          onClick={() => {
            const currentStatus =
              row?.goal_user_mappings[0]?.status === "active" ? true : false;
            handleChangeStatus(row?.reference_no, currentStatus);

            // setAssignGoalModalView(true);
          }}
          className="cursor-pointer "
        >
          <ImageView
            src={
              row?.goal_user_mappings[0]?.status === "active"
                ? PauseBtn
                : playBtn
            }
            className={
              row?.goal_user_mappings[0]?.status !== "active" && "add-icon"
            }
          />
        </span>,
      ],
      Archive: [
        <span
          onClick={() => {
            setArchiveGoal(true);
            setGoalUserMappingId(row?.goal_user_mappings[0]?.id);
          }}
          className="cursor-pointer"
        >
          <ImageView src={archiveGoalIcon} className="add-icon" />
        </span>,
      ],
    };
  });
  return tableData;
};

export const TableDataArchivedGoals = ({ data, updateGoalUserMapping }) => {
  const tableData = data?.map((row) => {
    const words = row?.description?.split(".");
    const dateString = row?.goal_user_mappings[0]?.start_date;
    const formattedDate = dateString?.split("T")[0];
    const dateAssigned = formattedDate?.replace(/-/g, "/");
    return {
      key: row?.id,
      RefNo: row?.reference_no,
      goalTitle: row?.title,
      GoalTitle: (
        <div className="d-flex gap-10">
          <span>{row?.title}</span>

          {row?.description && (
            <Tooltip
              overlayClassName="lm-table-tooltip"
              color={"#ffff"}
              placement="bottom"
              style={{ minWidth: "15px" }}
              title={
                <section className="clr-dark-grey d-flex flex-column gap-4 p-12">
                  <h5 className="f-13 lh-18 f-w-600 clr-dark-grey">
                    {row.title}
                  </h5>
                  <ul>
                    {words.map((line) => {
                      return (
                        line.length > 0 && (
                          <li className="f-12 lh-18 f-w-400 clr-grey-charchoal">
                            {line}
                          </li>
                        )
                      );
                    })}
                  </ul>
                </section>
              }
            >
              <span style={{ minWidth: "15px !important" }}>
                {infoIconYellow}
              </span>
            </Tooltip>
          )}
        </div>
      ),

      DateAssigned: dateAssigned,

      State: (
        <span
          className={`cursor-none-click technicians-client-status`}
          style={{ color: "red" }}
        >
          {row?.goal_user_mappings[0]?.state?.replace("_", " ")}
        </span>
      ),

      UnArchive: [
        <span
          onClick={() => {
            // setGoalUserMappingId(row?.goal_user_mappings[0]?.id)
            updateGoalUserMapping(
              { state: "un_archived" },
              row?.goal_user_mappings[0]?.id
            );
          }}
          className="actions-tag actions-tag-red  cursor-pointer"
          style={{ width: "40px", height: "40px" }}
        >
          {UnarchiveGoalIcon}
        </span>,
      ],
    };
  });
  return tableData;
};

export const TableHeadClientDocuments = [
  {
    title: "File Name",
    dataIndex: "fileName",
    key: "fileName",
  },
  {
    title: "",
    dataIndex: "Actions",
    key: "Actions",
  },
];

export const TableDataClientDocuments = ({ data, handleDeleteDoc }) => {
  const handleDownload = async (pdfUrl, filename) => {
    try {
      const response = await fetch(pdfUrl);
      const blob = await response.blob();

      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", filename);
      link.click();
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };
  const tableData = data?.map((row) => {
    return {
      key: row?.id,
      fileName: row?.filename,
      Actions: (
        <div className="client-doc-action-btns">
          <button
            className="delete-btn"
            title="Delete"
            onClick={() => handleDeleteDoc(row?.id)}
          >
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="32" height="32" rx="5" fill="#FBF1F1" />
              <mask
                id="mask0_2815_24795"
                // style="mask-type:alpha"
                maskUnits="userSpaceOnUse"
                x="6"
                y="6"
                width="20"
                height="20"
              >
                <rect x="6" y="6" width="20" height="20" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_2815_24795)">
                <path
                  d="M11.832 23.5C11.3737 23.5 10.9813 23.3368 10.6549 23.0104C10.3286 22.684 10.1654 22.2917 10.1654 21.8333V11H9.33203V9.33333H13.4987V8.5H18.4987V9.33333H22.6654V11H21.832V21.8333C21.832 22.2917 21.6688 22.684 21.3424 23.0104C21.0161 23.3368 20.6237 23.5 20.1654 23.5H11.832ZM20.1654 11H11.832V21.8333H20.1654V11ZM13.4987 20.1667H15.1654V12.6667H13.4987V20.1667ZM16.832 20.1667H18.4987V12.6667H16.832V20.1667Z"
                  fill="#EF4747"
                />
              </g>
            </svg>
          </button>
          <button
            className="download-btn"
            title="Download"
            onClick={() => handleDownload(row?.url, row?.filename)}
          >
            <HiOutlineDocumentDownload color="#662D91" />{" "}
          </button>
        </div>
      ),
    };
  });
  return tableData;
};

export const TableDataAssignedGoalsSecond = ({
  data,
  setAssignGoalModalView,
}) => {
  const tableData = data?.map((row) => {
    const words = row?.description?.split(".");

    return {
      key: row?.id,
      RefNo: row?.reference_no,
      goalTitle: row?.title,
      GoalTitle: (
        <div className="d-flex gap-10">
          <span>{row?.title}</span>

          {row?.description && (
            <Tooltip
              overlayClassName="lm-table-tooltip"
              color={"#ffff"}
              placement="right"
              style={{ minWidth: "15px" }}
              title={
                <section className="clr-dark-grey d-flex flex-column gap-4 p-12">
                  <h5 className="f-13 lh-18 f-w-600 clr-dark-grey">
                    {row.title}
                  </h5>
                  <ul>
                    {words.map((line) => {
                      return (
                        line.length > 0 && (
                          <li className="f-12 lh-18 f-w-400 clr-grey-charchoal">
                            {line}
                          </li>
                        )
                      );
                    })}
                  </ul>
                </section>
              }
            >
              <span style={{ minWidth: "15px" }}> {infoIconYellow}</span>
            </Tooltip>
          )}
        </div>
      ),
      Actions: [
        <span
          onClick={() => {
            setAssignGoalModalView(true);
          }}
          className="cursor-pointer"
        >
          <ImageView src={Addicon} className="add-icon" />
        </span>,
      ],
    };
  });
  return tableData;
};
export const TableDataAssignedInProgressGoals = ({ data }) => {
  const tableData = data?.map((row) => {
    const words = row?.description?.split(".");

    return {
      key: row?.id,
      Client: (
        <div className="technician-client">
          {row?.first_name + " " + row?.last_name}
          <span>{row?.email}</span>
        </div>
      ),
      RefNo: row?.reference_no,
      goalTitle: row?.title,
      GoalTitle: (
        <div className="d-flex gap-10">
          <span>{row?.title}</span>

          {row?.description && (
            <Tooltip
              overlayClassName="lm-table-tooltip"
              color={"#ffff"}
              placement="right"
              style={{ minWidth: "15px" }}
              title={
                <section className="clr-dark-grey d-flex flex-column gap-4 p-12">
                  <h5 className="f-13 lh-18 f-w-600 clr-dark-grey">
                    {row.title}
                  </h5>
                  <ul>
                    {words.map((line) => {
                      return (
                        line.length > 0 && (
                          <li className="f-12 lh-18 f-w-400 clr-grey-charchoal">
                            {line}
                          </li>
                        )
                      );
                    })}
                  </ul>
                </section>
              }
            >
              {infoIconYellow}
            </Tooltip>
          )}
        </div>
      ),
      Status: [
        <span
          className={`cursor-none-click technicians-client-status ${
            row?.level == "in_progress" ? "in-progress" : "mastered"
          }`}
          style={{ textTransform: "capitalize" }}
        >
          {row?.level?.replace(/_/g, " ")}
        </span>,
      ],
    };
  });
  return tableData;
};

export const TableHeadsDailySessionsClients = [
  {
    title: "Date",
    dataIndex: "Date",
    key: "Date",
  },
  {
    title: "Time",
    dataIndex: "Time",
    key: "Time",
  },
  {
    title: "Technician",
    dataIndex: "Technician",
    key: "Technician",
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
  },
  {
    title: "",
    dataIndex: "Actions",
    key: "Actions",
  },
];

export const ProgressReportMasteredHeads = [
  {
    title: "Goal Name",
    dataIndex: "GoalName",
    key: "GoalName",
  },
  {
    title: "Mastery Date",
    dataIndex: "MasteryDate",
    key: "MasteryDate",
  },
  {
    title: "Mastery Time (Hrs)",
    dataIndex: "MasteryTime",
    key: "MasteryTime",
  },
];

export const ProgressReportInProgressHeads = [
  {
    title: "Goal Name",
    dataIndex: "GoalName",
    key: "GoalName",
  },
  {
    title: "Start Date",
    dataIndex: "StartDate",
    key: "StartDate",
  },
  {
    title: "Highest Score %",
    dataIndex: "HighestScore",
    key: "HighestScore",
  },
  {
    title: "Lowest Score %",
    dataIndex: "LowestScore",
    key: "LowestScore",
  },
];

export const ProgressReportAssignedHeads = [
  {
    title: "Goal Name",
    dataIndex: "GoalName",
    key: "GoalName",
  },
  {
    title: "Assigned Date",
    dataIndex: "AssignedDate",
    key: "AssignedDate",
  },
  {
    title: "Level",
    dataIndex: "Level",
    key: "Level",
  },
];

export const TableHeadsClientBillingDetails = [
  {
    title: "Date of Service",
    dataIndex: "dateOfService",
    key: "dateOfService",
    align: "center",
  },
  {
    title: "Nick Name",
    dataIndex: "nickName",
    key: "nickName",
    align: "center",
  },
  {
    title: "Client ID",
    dataIndex: "clientId",
    key: "clientId",
    align: "center",
  },
  {
    title: "Provider Name",
    dataIndex: "providerName",
    key: "providerName",
    align: "center",
  },
  {
    title: "Start Time",
    dataIndex: "startTime",
    key: "startTime",
    align: "center",
  },
  {
    title: "End Time",
    dataIndex: "endTime",
    key: "endTime",
    align: "center",
  },
  {
    title: "Total Units",
    dataIndex: "totalUnits",
    key: "totalUnits",
    align: "center",
  },
  {
    title: "Billing Code",
    dataIndex: "billingCode",
    key: "billingCode",
    align: "center",
  },
  {
    title: "Modifier Code",
    dataIndex: "modifierCode",
    key: "modifierCode",
    // align: 'center'
  },
  {
    title: "Total Amount",
    dataIndex: "totalAmount",
    key: "totalAmount",
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
    align: "center",
  },
];

export const TableHeadsAllSessions = ({ data }) => {
  const header = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Age",
      dataIndex: "Age",
      key: "Age",
      render: (age) => (
        <span className="f-w-600 clr-grey-charchoal">{age} </span>
      ),
    },
    {
      title: "Date Joined",
      dataIndex: "DateJoined",
      key: "DateJoined",
    },

    {
      title: "Archive",
      dataIndex: "Archive",
      key: "Archive",
      render: (Archive) => <a>{Archive}</a>,
    },
  ];
  return header;
};
export const TableHeadsAllSessionsTech = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Experience",
    dataIndex: "experience",
    key: "experience",
    render: (age) => <span className="f-w-600 clr-grey-charchoal">{age} </span>,
  },
  {
    title: "Date Joined",
    dataIndex: "DateJoined",
    key: "DateJoined",
  },
  {
    title: "Current Client",
    dataIndex: "current_client",
    key: "current_client",
  },
  {
    title: "Total Goals Worked",
    dataIndex: "total_worked_goals",
    key: "total_worked_goals",
  },

  {
    title: "Archive",
    dataIndex: "Archive",
    key: "Archive",
    render: (Archive) => <a>{Archive}</a>,
  },
];
export const TableHeadsAllSessionsDirector = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },

  {
    title: "Date Joined",
    dataIndex: "DateJoined",
    key: "DateJoined",
  },
  {
    title: "Archive",
    dataIndex: "Archive",
    key: "Archive",
  },
];
export const TableHeadsAllSessionsDirectorArchived = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },

  {
    title: "Date Joined",
    dataIndex: "DateJoined",
    key: "DateJoined",
  },
  {
    title: "Unarchive",
    key: "Unarchive",
    dataIndex: "Unarchive",
  },
];
export const TableDataAllSessionsTech = ({ data, setArchive }) => {
  const responseData = data?.map((row) => {
    const words = row.full_name.split(" ").filter((word) => word.trim() !== "");
    let extractedLetters;
    if (words.length >= 2) {
      const firstLetterWord1 = words[0][0]?.toUpperCase();
      const firstLetterWord2 = words[1][0]?.toUpperCase();
      extractedLetters = firstLetterWord1 + firstLetterWord2;
    }
    return {
      id: row.id || row.slug,
      email: row.email,
      key: row.id || row.slug,
      Age: row.age || "-",
      slug: row.slug,
      form_completed: row.form_completed,

      Name: row.full_name,
      name: (
        <div className="client-info-data" data-column-name="name">
          {row?.profile_image ? (
            <img
              data-column-name="name"
              className=""
              alt="client-profile"
              src={row?.profile_image || "/src/assets/images/tkxel_logo.png"}
            ></img>
          ) : (
            <span
              data-column-name="name"
              className="name-show"
              alt="client-profile"
              // src={row?.profile_image || "/src/assets/images/tkxel_logo.png"}
            >
              {extractedLetters}
            </span>
          )}
          <span data-column-name="name">{row.full_name}</span>
        </div>
      ),
      experience: row?.experience || "-",
      total_worked_goals: row?.total_worked_goals || "-",
      current_client: row.current_clients?.length
        ? row.current_clients[0]
        : "-",

      DateJoined: row.enrolled_date,
      // GoalsAssigned: "Accepting No",
      Archive: (
        <span onClick={() => setArchive(true)} className="cursor-pointer">
          <ImageView className={"img-fluid"} src={archiveIcon}></ImageView>
        </span>
      ),
    };
  });
  return responseData;
};
export const TableDataAllSessionsDirector = ({
  data,
  setArchive,
  setUnArchive,
  user,
}) => {
  const responseData = data?.map((row) => {
    const words = row.full_name.split(" ").filter((word) => word.trim() !== "");
    let extractedLetters;
    if (words.length >= 2) {
      const firstLetterWord1 = words[0][0]?.toUpperCase();
      const firstLetterWord2 = words[1][0]?.toUpperCase();
      extractedLetters = firstLetterWord1 + firstLetterWord2;
    }
    return {
      id: row.id || row.slug,
      email: row.email,
      key: row.id || row.slug,
      Age: row.age || "-",
      slug: row.slug,
      form_completed: row.form_completed,

      Name: row.full_name,
      name: (
        <div className="client-info-data cursor-simple" data-column-name="name">
          {row?.profile_image ? (
            <img
              data-column-name="name"
              className=""
              alt="client-profile"
              src={row?.profile_image || "/src/assets/images/tkxel_logo.png"}
            ></img>
          ) : (
            <span
              data-column-name="name"
              className="name-show"
              alt="client-profile"
              // src={row?.profile_image || "/src/assets/images/tkxel_logo.png"}
            >
              {extractedLetters}
            </span>
          )}
          <span data-column-name="name">{row.full_name}</span>
        </div>
      ),
      total_worked_goals: row?.total_worked_goals,
      current_client: row.current_clients?.length ? row.current_clients[0] : "",

      DateJoined: row.enrolled_date,
      // GoalsAssigned: "Accepting No",
      Archive: (
        <>
          {user?.slug !== row.slug && (
            <span onClick={() => setArchive(true)} className="cursor-pointer">
              <ImageView className={"img-fluid"} src={archiveIcon}></ImageView>
            </span>
          )}
        </>
      ),
      Unarchive: (
        <span
          onClick={() => setUnArchive(true)}
          className="actions-tag actions-tag-red  cursor-pointer"
        >
          {UnarchiveRed}
        </span>
      ),
    };
  });
  return responseData;
};
export const TableHeadsYourClients = [
  {
    title: "Name",
    dataIndex: "Name",
    key: "Name",
  },
  {
    title: "Age",
    dataIndex: "Age",
    key: "Age",
  },
  {
    title: "Phone No",
    dataIndex: "PhoneNo",
    key: "PhoneNo",
  },
  {
    title: "Address",
    dataIndex: "Address",
    key: "Address",
  },

  {
    title: "View Goals",
    dataIndex: "Actions",
    key: "Actions",
    render: (Actions) => (
      <a>{<div className="d-flex gap-12">{Actions}</div>}</a>
    ),
  },
];
export const TableHeadsAssignedGoals = [
  {
    title: "Ref.No",
    dataIndex: "RefNo",
    key: "RefNo",
  },
  {
    title: "Goal Title",
    dataIndex: "GoalTitle",
    key: "GoalTitle",
  },
  {
    title: "Status",
    dataIndex: "Progress",
    key: "Progress",
  },
  {
    title: "Date Assigned",
    dataIndex: "DateAssigned",
    key: "DateAssigned",
  },

  {
    title: "Action",
    dataIndex: "Actions",
    key: "Actions",
    render: (Actions) => (
      <a>{<div className="d-flex gap-12">{Actions}</div>}</a>
    ),
  },
  {
    title: "Archive",
    dataIndex: "Archive",
    key: "Arvhive",
  },
];
export const TableHeadsArchivedGoals = [
  {
    title: "Ref.No",
    dataIndex: "RefNo",
    key: "RefNo",
  },
  {
    title: "Goal Title",
    dataIndex: "GoalTitle",
    key: "GoalTitle",
  },
  {
    title: "Date Assigned",
    dataIndex: "DateAssigned",
    key: "DateAssigned",
  },
  {
    title: "State",
    dataIndex: "State",
    key: "State",
  },
  {
    title: "UnArchive",
    dataIndex: "UnArchive",
    key: "UnArvhive",
  },
];
export const TableHeadsAssignedGoalsTech = [
  {
    title: "Ref.No",
    dataIndex: "RefNo",
    key: "RefNo",
  },
  {
    title: "Goal Title",
    dataIndex: "GoalTitle",
    key: "GoalTitle",
  },
  {
    title: "Status",
    dataIndex: "Progress",
    key: "Progress",
  },
];
export const TableHeadsAssignedGoalsSecond = [
  {
    title: "Ref.No",
    dataIndex: "RefNo",
    key: "RefNo",
  },
  {
    title: "Goal Title",
    dataIndex: "GoalTitle",
    key: "GoalTitle",
  },

  {
    title: "Action",
    dataIndex: "Actions",
    key: "Actions",
    render: (Actions) => (
      <a>{<div className="d-flex gap-12">{Actions}</div>}</a>
    ),
  },
];
export const TableHeadsInProgressGoals = [
  {
    title: "Client",
    dataIndex: "Client",
    key: "Client",
  },
  {
    title: "Ref.No",
    dataIndex: "RefNo",
    key: "RefNo",
  },
  {
    title: "Goal Title",
    dataIndex: "GoalTitle",
    key: "GoalTitle",
  },

  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
    // render: (Actions) => (
    //   <a>{<div className="d-flex gap-12">{Actions}</div>}</a>
    // ),
  },
];
export const TableHeadsPendingRequests = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Age",
    dataIndex: "Age",
    key: "Age",
    render: (age) => <span className="f-w-600 clr-grey-charchoal">{age} </span>,
  },
  {
    title: "Date Joined",
    dataIndex: "DateJoined",
    key: "DateJoined",
  },
  {
    title: "Action",
    key: "Actions",
    dataIndex: "Actions",
  },
];
export const TableHeadsArchive = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Age",
    dataIndex: "Age",
    key: "Age",
    render: (age) => <span className="f-w-600 clr-grey-charchoal">{age}</span>,
  },
  {
    title: "Date Joined",
    dataIndex: "DateJoined",
    key: "DateJoined",
  },
  {
    title: "Unarchive",
    key: "Unarchive",
    dataIndex: "Unarchive",
  },
];

export const TableHeadsOriginalTargets = [
  {
    title: "Insurance Type",
    dataIndex: "InsuranceType",
    key: "InsuranceType",
  },
  {
    title: "Code Group",
    dataIndex: "CodeGroup",
    key: "CodeGroup",
  },
  {
    title: "Authorized Units",
    dataIndex: "AuthorizedUnits",
    key: "AuthorizedUnits",
  },
  {
    title: "Per Day",
    dataIndex: "PerDay",
    key: "PerDay",
  },
  {
    title: "Per Week",
    dataIndex: "PerWeek",
    key: "PerWeek",
  },
  {
    title: "Per 30 Days",
    dataIndex: "Per30Days",
    key: "Per30Days",
  },
  {
    title: "Differential From Target",
    dataIndex: "DifferentialFromTarget",
    key: "DifferentialFromTarget",
  },
];
export const TableHeadsToday = [
  {
    title: "Insurance type",
    dataIndex: "InsuranceType",
    key: "InsuranceType",
  },
  {
    title: "Code Group",
    dataIndex: "CodeGroup",
    key: "CodeGroup",
  },
  {
    title: "Authorized Units",
    dataIndex: "AuthorizedUnits",
    key: "AuthorizedUnits",
  },
  {
    title: "Target Units Used",
    dataIndex: "Target_Units_Used",
    key: "Target_Units_Used",
  },
  {
    title: "Actual Units Used",
    dataIndex: "Actual_Units_Used",
    key: "Actual_Units_Used",
  },
  {
    title: "Units Remaining",
    dataIndex: "Units_Remaining",
    key: "Units_Remaining",
  },
  {
    title: "Differential From Target",
    dataIndex: "DifferentialFromTarget",
    key: "DifferentialFromTarget",
  },
];
export const TableDataOriginalTargets = ({ data, updatedTarget }) => {
  const response = data?.map((item) => {
    return {
      id: item.id ?? Math.random(),
      InsuranceType: (
        <p className="clr-grey-charchoal f-w-400 text-capitalize">
          {item.unit_type ?? "-"}
        </p>
      ),
      CodeGroup: item.unit_code ?? "-",
      AuthorizedUnits: item.authorized_units ?? "-",
      PerDay: updatedTarget
        ? item.up_target_per_day || "-"
        : item.per_day || "-",
      PerWeek: updatedTarget
        ? item.up_target_per_week || "-"
        : item.per_week ?? "-",
      Per30Days: updatedTarget
        ? item.up_target_per_30_days || "-"
        : item.per_30_days || "-",
      DifferentialFromTarget: (
        <p className="clr-td-last-child">
          {item?.differential_from_target ?? "-"}
        </p>
      ),
      Target_Units_Used: item?.target_units_used ?? "-",
      Actual_Units_Used: item?.actual_used_units ?? "-",
      Units_Remaining: item?.units_remaining ?? "-",
    };
  });

  return response;
};

import { useEffect, useState } from "react";
import RadioButtons from "../form-controls/input/RadioButtonsSection/RadioButtons";
import RadioButtonsFormSection from "../form-controls/input/RadioButtonsSection/RadioButtonsFormSection";
import "./DailyDataSheetTabs.scss";
import { useMatch, useParams } from "react-router-dom";
import { Button } from "antd";
import { TextFieldFormik } from "../form-controls/input/text.field.formik";
import httpClient from "../../services/axios";
import { createNotification } from "../../helpers/createNotifications";
import { logout } from "../../store/user/userSlice";
import { handleErrorResponse } from "../../helpers/responseHandler";

const AddCompliance = ({
  serviceNoteApproved,
  restrictedFieldNumbers,
  markAsCompleted,
  isDataSheetLocked,
  intializeAutoSaveRequests,
  setIntializeAutoSaveRequests,
}) => {
  const saveBtnText = serviceNoteApproved ? "Approved" : "Save";
  const { params } = useMatch("*");
  const route = Object.values(params)[0];
  const { id, serviceId } = useParams();
  const newObject = {
    difficulty: "",
    title: "",
    complied: false,
  };
  const [action, setAction] = useState(false);
  const [saveInProgress, SetSaveInProgress] = useState(false);
  const [payload, setPayload] = useState([
    {
      difficulty: "",
      title: "",
      complied: false,
    },
  ]);
  const [trials, setTrials] = useState();
  const radioOption = [
    { value: "Hard", key: "hard" },
    { value: "Medium", key: "medium" },
    { value: "Easy", key: "easy" },
  ];


  useEffect(() => {
    const getComplianceData = async () => {
      try {
        if (payload.length) {
          const res = await httpClient.get("api/v1/compliances", {
            params: { data_sheet: { id: id } },
          });
          if (res.data.length) {
            setPayload(res.data);
            markAsCompleted("compliances");
          } else {
            setPayload(payload);
          }
          return true;
        }
      } catch (error) {
        handleErrorResponse(error);
      }
    };
    if (id && (route.includes("datasheet") || route.includes("dataSheet"))) {
      getComplianceData();
    }
  }, [id]);

  useEffect(() => {
    const getTrialsData = async (id) => {
      try {
        const res = await httpClient.get(
          "api/v1/compliances/successfull_trials",
          {
            params: { data_sheet: { id: id } },
          }
        );
        if (res?.data) {
          setTrials(res.data);
        }
        return true;
      } catch (error) {
        handleErrorResponse(error);
      }
    };
    if (id && (route.includes("datasheet") || route.includes("dataSheet")))
      getTrialsData(id);
  }, [id, action]);

  const hanldeSave = async () => {
    try {
      SetSaveInProgress(true)
      if (payload.length) {
        const res = await httpClient.post(
          "/api/v1/compliances/update_or_create",
          {
            data_sheet: {
              id: id,
              compliances_attributes: payload,
            },
          }
        );
        if (res.data) {
          setPayload(res.data);
          setAction(!action);
          markAsCompleted("compliances");
          createNotification("success", "Compliance Saved");
        }
        SetSaveInProgress(false)
        return true;
      }
    } catch (error) {
      SetSaveInProgress(false)
      handleErrorResponse(error);
    }
  };

  const handleAutoSave = async () => {
    const validItems = [];
    const inValidItems = [];

    payload.forEach((item) => {
      if (!!item.id || (!!item?.title && !!item?.difficulty)) {
        validItems.push(item);
      } else {
        inValidItems.push(item);
      }
    });

    try {
      if (validItems.length) {
        const res = await httpClient.post(
          "/api/v1/compliances/update_or_create",
          {
            data_sheet: {
              id: id,
              compliances_attributes: validItems,
            },
          }
        );
        if (res.data) {
          const updatedPayload = [...res.data, ...inValidItems];
          setPayload(updatedPayload);
        }
        return true;
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  useEffect(() => {
    if (intializeAutoSaveRequests) {
      console.log("Saving compliances");
      handleAutoSave();
      setIntializeAutoSaveRequests(false);
    }
  }, [intializeAutoSaveRequests]);

  const allFiledsAreFilled =
    !!payload.length &&
    payload.every((obj) => !!obj?.difficulty.length && !!obj.title.length);

  const validNoOfcompliances =
    payload?.length && payload?.length >= restrictedFieldNumbers;

  return (
    <>
      <div className="compilance-header">
        <div style={{ marginTop: "10px" }}>
          <h6 style={{ color: "red" }}>
            Note: You have to enter {restrictedFieldNumbers} compliances Minimum
          </h6>
        </div>
        <div className="compilance-header-wrapper">
          <p className="f-14 f-w-600">
            Total Trials:{" "}
            <span>
              {trials?.sucessful_trials}/{trials?.total_compliances}
            </span>{" "}
          </p>
        </div>

        {payload?.length > 0 &&
          payload?.map((item, index) => {
            return (
              <div className="demand-wrapper" key={index}>
                <ul className="demand-wrapper-ul">
                  <li style={{ minWidth: "120px" }}>
                    <div>
                      <label className="f-15 f-w-600 clr-dark-grey">
                        Demand {index + 1}
                      </label>
                    </div>

                    <TextFieldFormik
                      key={item?.index}
                      placeholder={"Enter Title"}
                      type={"text"}
                      wrapperClassName={"max-w-input-of w-100"}
                      tabIndex={1}
                      name={`title`}
                      handleChange={(e) => {
                        const updatedPayload = [...payload];
                        updatedPayload[index].title = e.target.value;
                        setPayload(updatedPayload);
                      }}
                      values={item?.title || ""}
                    />
                  </li>

                  <li>
                    <div>
                      <label className="f-15 f-w-600 clr-dark-grey mb-8">
                        Complied
                      </label>
                    </div>
                    <div class="switch_box box_1">
                      <div className="d-flex align-items-center gap-8">
                        <label className="f-15 f-w-600">No</label>
                        <input
                          type="checkbox"
                          class="switch_1"
                          onChange={(e) => {
                            const updatedPayload = [...payload];
                            updatedPayload[index].complied = e.target.checked;
                            setPayload(updatedPayload);
                          }}
                          checked={item?.complied}
                        />
                        <label className="f-15 f-w-600">Yes</label>
                      </div>
                    </div>
                  </li>

                  <li>
                    <RadioButtonsFormSection
                      RadioButtons={radioOption.map((content) => {
                        return (
                          <RadioButtons
                            id={content?.key}
                            key={content?.key}
                            name={`payload[${index}].difficulty`}
                            onChange={(e) => {
                              const updatedPayload = [...payload];
                              updatedPayload[index].difficulty = e.target.value;
                              setPayload(updatedPayload);
                            }}
                            Label={content.value}
                            checked={
                              item.difficulty === content?.key ? true : false
                            }
                          />
                        );
                      })}
                      label={"Difficulty"}
                    />
                  </li>
                </ul>
              </div>
            );
          })}
      </div>
      <div className="objective-btns-wrapper">
        <button
          onClick={() => setPayload([...payload, newObject])}
          className="add-main-btn"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
          >
            <path d="M6 0V12" stroke="#ABABAB" />
            <path d="M12 6L-1.78814e-07 6" stroke="#ABABAB" />
          </svg>
        </button>
        <button
          onClick={() => hanldeSave()}
          disabled={
            !allFiledsAreFilled ||
            serviceNoteApproved ||
            !validNoOfcompliances ||
            isDataSheetLocked ||
            saveInProgress
          }
          className="save-btn"
        >
          {saveBtnText}
        </button>
      </div>
    </>
  );
};
export default AddCompliance;

import { Fragment } from "react";
import { Navigate, Route, Routes, useMatch } from "react-router-dom";
import NotFound from "../pages/notfound/notFound";

import PrivateRouteCheck from "./PrivateRoutes";
import privateRoutes from "./privateRoutesObject";

import PublicRouteCheck from "./PublicRoutes";
import publicRoutes from "./publicRoutesObject";

import ProtectedRouteCheck from "./ProtectedRoutes";
import protectedRoutes from "./protectedRoutesObject";
import OnBoardingPage from "../pages/onBoardingForm/onboardingForm";
import PublicRoute from "./PublicRoutes";
import DashboardLogin from "../Containers/Dashboard Login/DashboardLogin";
import Client from "../Containers/Dashboard/Client/Client";
import TechnicianDahaboard from "../Containers/TechnicianDahaboard/TechnicianDahaboard";
import { GetCurrentUser } from "../utils/utilAuth";
import ClientLogin from "../Containers/Dashboard Login/ClientLogin";
import Dashboard from "../Containers/Dashboard/Dashboard";
import ForgotPasswordForm from "../pages/ForgotPassword/ForgotPasswordForm";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
import CheckEmail from "../components/CheckEmail/checkEmail";
import NewPassword from "../pages/newPassword/newPassword";
import PasswordSuccess from "../pages/newPassword/resetsuccessfull";

function AppRoutes({ currentUser, loggingStatus }) {
  const match = useMatch("*"); // or useMatch()
  const currentURL = match?.pathname || "";
  const client = currentURL.includes("clientForm");
  const user = GetCurrentUser();
  return (
    <Fragment>
      <Routes>
        {user?.role === "client" && (
          <>
            <Route
              path={"*"}
              exact
              strict
              element={
                loggingStatus ? (
                  <Navigate to={"clientForm/onboardingform"} replace />
                ) : (
                  <Navigate to={"clientFormlogin"} replace />
                )
              }
            />

            <Route
              path="clientForm/onboardingform"
              element={
                loggingStatus ? (
                  <ProtectedRouteCheck client={true}>
                    <OnBoardingPage />
                  </ProtectedRouteCheck>
                ) : (
                  <Navigate to={"/clientFormlogin"} replace />
                )
              }
            />
          </>
        )}
        {!!!user?.role && client && (
          <Route
            path={"*"}
            element={
              loggingStatus ? (
                <ProtectedRouteCheck client={true}>
                  <OnBoardingPage />
                </ProtectedRouteCheck>
              ) : (
                <Navigate to={"clientFormlogin"} replace />
              )
            }
          />
        )}
        {!user?.role && !client && !!!loggingStatus && (
          <Route
            path={"*"}
            strict
            element={<Navigate to={"/dashboardlogin"} replace />}
          />
        )}
        {user?.role === "director" && (
          <>
            <Route
              path={"*"}
              strict
              element={
                loggingStatus ? (
                  <Navigate to={"dashboard"} replace />
                ) : (
                  <Navigate to={"dashboardlogin"} replace />
                )
              }
            />
            {/* <Route
              path="/dashboardlogin"
              exact
              strict
              element={
                loggingStatus ? (
                  <Navigate to={"dashboard"} replace />
                ) : (
                  <PublicRoute>
                    <DashboardLogin />
                  </PublicRoute>
                )
              }
            /> */}
            <Route
              path="/dashboard"
              exact
              strict
              element={
                <ProtectedRouteCheck>
                  <Dashboard />
                </ProtectedRouteCheck>
              }
            />
            <Route
              path="/dashboard/sessions"
              exact
              strict
              element={
                <ProtectedRouteCheck>
                  <Dashboard />
                </ProtectedRouteCheck>
              }
            />
            <Route
              path="/directors"
              exact
              strict
              element={
                <ProtectedRouteCheck>
                  <Dashboard />
                </ProtectedRouteCheck>
              }
            />
            <Route
              path="/clientBilling"
              exact
              strict
              element={
                <ProtectedRouteCheck>
                  <Dashboard />
                </ProtectedRouteCheck>
              }
            />
             <Route
              path="/analysisDashboard"
              exact
              strict
              element={
                <ProtectedRouteCheck>
                  <Dashboard />
                </ProtectedRouteCheck>
              }
            />
            <Route
              path="/clients"
              exact
              strict
              element={
                <ProtectedRouteCheck>
                  <Dashboard />
                </ProtectedRouteCheck>
              }
            />
            <Route
              path="/technicians"
              exact
              strict
              element={
                <ProtectedRouteCheck>
                  <Dashboard />
                </ProtectedRouteCheck>
              }
            />
            <Route
              path="/clients/goals"
              exact
              strict
              element={
                <ProtectedRouteCheck>
                  <Dashboard />
                </ProtectedRouteCheck>
              }
            />
            <Route
              path="/clients/:id/units"
              exact
              strict
              element={
                <ProtectedRouteCheck>
                  <Dashboard />
                </ProtectedRouteCheck>
              }
            />
            <Route
              path="/technicians/inprogressgoals"
              exact
              strict
              element={
                <ProtectedRouteCheck>
                  <Dashboard />
                </ProtectedRouteCheck>
              }
            />
            <Route
              path="/dashboard/checkin/:dataSheetId/:userId/:id/:serviceId"
              exact
              strict
              element={
                <ProtectedRouteCheck>
                  <Dashboard />
                </ProtectedRouteCheck>
              }
            />
            <Route
              path="/dashboard/checkout/:dataSheetId/:userId/:id/:serviceId"
              exact
              strict
              element={
                <ProtectedRouteCheck>
                  <Dashboard />
                </ProtectedRouteCheck>
              }
            />
              <Route
              path="/dashboardT/checkin/:dataSheetId/:userId/:id/:serviceId"
              exact
              strict
              element={
                <ProtectedRouteCheck>
                  <Dashboard />
                </ProtectedRouteCheck>
              }
            />
            <Route
              path="/dashboardT/checkout/:dataSheetId/:userId/:id/:serviceId"
              exact
              strict
              element={
                <ProtectedRouteCheck>
                  <Dashboard />
                </ProtectedRouteCheck>
              }
            />
           u
            <Route
              path="/clients/:id"
              exact
              strict
              element={
                <ProtectedRouteCheck>
                  <Dashboard />
                </ProtectedRouteCheck>
              }
            />
            <Route
              path="/clients/:id/documents"
              exact
              strict
              element={
                <ProtectedRouteCheck>
                  <Dashboard />
                </ProtectedRouteCheck>
              }
            />

            <Route
              path="/clients/:id/sessions"
              exact
              strict
              element={
                <ProtectedRouteCheck>
                  <Dashboard />
                </ProtectedRouteCheck>
              }
            />
            <Route
              path="/technicians/:id/sessions"
              exact
              strict
              element={
                <ProtectedRouteCheck>
                  <Dashboard />
                </ProtectedRouteCheck>
              }
            />
            <Route
              path="/technicians/:id"
              exact
              strict
              element={
                <ProtectedRouteCheck>
                  <Dashboard />
                </ProtectedRouteCheck>
              }
            />
            <Route
              path="/clients/reviewForm/:id"
              exact
              strict
              element={
                <ProtectedRouteCheck>
                  <OnBoardingPage />
                </ProtectedRouteCheck>
              }
            />
            <Route
              path="/appointments"
              exact
              strict
              element={
                <ProtectedRouteCheck>
                  <Dashboard />
                </ProtectedRouteCheck>
              }
            />
            <Route
              path="/dashboard/datasheet/:id/:userId/:appointId/:serviceId"
              exact
              strict
              element={
                <ProtectedRouteCheck>
                  <Dashboard />
                </ProtectedRouteCheck>
              }
            />
            <Route
              path="/dashboard/servicenote/:id/:serviceId/:dataSheetId"
              exact
              strict
              element={
                <ProtectedRouteCheck>
                  <Dashboard />
                </ProtectedRouteCheck>
              }
            />
            <Route
              path="/appointments/servicenote/:id/:serviceId/:dataSheetId"
              exact
              strict
              element={
                <ProtectedRouteCheck>
                  <Dashboard />
                </ProtectedRouteCheck>
              }
            />

            <Route
              path="/dashboardT/datasheet/:id/:userId/:appointId/:serviceId"
              exact
              strict
              element={
                <ProtectedRouteCheck>
                  <Dashboard />
                </ProtectedRouteCheck>
              }
            />
            <Route
              path="/dashboardT/servicenote/:id/:serviceId/:dataSheetId"
              exact
              strict
              element={
                <ProtectedRouteCheck>
                  <Dashboard />
                </ProtectedRouteCheck>
              }
            />
          </>
        )}
        {user?.role === "technician" && (
          <>
            <Route
              path={"*"}
              strict
              element={
                loggingStatus ? (
                  <Navigate to={"techdashboard"} replace />
                ) : (
                  <Navigate to={"dashboardlogin"} replace />
                )
              }
            />
            <Route
              path="/techdashboard"
              exact
              strict
              element={
                <ProtectedRouteCheck>
                  <Dashboard />
                </ProtectedRouteCheck>
              }
            />
            <Route
              path="/techdashboard/sessions"
              exact
              strict
              element={
                <ProtectedRouteCheck>
                  <Dashboard />
                </ProtectedRouteCheck>
              }
            />
            <Route
              path="/techdashboard/pendingsessions"
              exact
              strict
              element={
                <ProtectedRouteCheck>
                  <Dashboard />
                </ProtectedRouteCheck>
              }
            />
            <Route
              path="/techdashboard/datasheet/:id/:userId/:appointId"
              exact
              strict
              element={
                <ProtectedRouteCheck>
                  <Dashboard />
                </ProtectedRouteCheck>
              }
            />
            <Route
              path="/techdashboard/goalsObjective/:id/:userId"
              exact
              strict
              element={
                <ProtectedRouteCheck>
                  <Dashboard />
                </ProtectedRouteCheck>
              }
            />
            <Route
              path="/techdashboard/checkout/:dataSheetId/:userId/:id"
              exact
              strict
              element={
                <ProtectedRouteCheck>
                  <Dashboard />
                </ProtectedRouteCheck>
              }
            />
            <Route
              path="/techdashboard/checkin/:dataSheetId/:userId/:id"
              exact
              strict
              element={
                <ProtectedRouteCheck>
                  <Dashboard />
                </ProtectedRouteCheck>
              }
            />
            <Route
              path="/techdashboard/checkout/:dataSheetId/:userId/:id/:serviceId"
              exact
              strict
              element={
                <ProtectedRouteCheck>
                  <Dashboard />
                </ProtectedRouteCheck>
              }
            />
            <Route
              path="/yourClients/checkout/:dataSheetId/:userId/:id/:serviceId"
              exact
              strict
              element={
                <ProtectedRouteCheck>
                  <Dashboard />
                </ProtectedRouteCheck>
              }
            />
            <Route
              path="/techdashboard/checkin/:dataSheetId/:userId/:id/:serviceId"
              exact
              strict
              element={
                <ProtectedRouteCheck>
                  <Dashboard />
                </ProtectedRouteCheck>
              }
            />
            <Route
              path="/techdashboard/dataSheet/:id/:userId/:appointId/:serviceId"
              exact
              strict
              element={
                <ProtectedRouteCheck>
                  <Dashboard />
                </ProtectedRouteCheck>
              }
            />
            <Route
              path="/yourClients/checkin/:dataSheetId/:userId/:id/:serviceId"
              exact
              strict
              element={
                <ProtectedRouteCheck>
                  <Dashboard />
                </ProtectedRouteCheck>
              }
            />

            <Route
              path="/techdashboard/servicenote/:id/:dataSheetId"
              exact
              strict
              element={
                <ProtectedRouteCheck>
                  <Dashboard />
                </ProtectedRouteCheck>
              }
            />
            <Route
              path="/techdashboard/serviceNote/:id/:serviceId/:dataSheetId"
              exact
              strict
              element={
                <ProtectedRouteCheck>
                  <Dashboard />
                </ProtectedRouteCheck>
              }
            />
            <Route
              path="/yourClients/datasheet/:id/:userId/:appointId/:serviceId"
              exact
              strict
              element={
                <ProtectedRouteCheck>
                  <Dashboard />
                </ProtectedRouteCheck>
              }
            />
            <Route
              path="/yourClients/servicenote/:id/:serviceId/:dataSheetId"
              exact
              strict
              element={
                <ProtectedRouteCheck>
                  <Dashboard />
                </ProtectedRouteCheck>
              }
            />
            <Route
              path="/yourClients"
              exact
              strict
              element={
                <ProtectedRouteCheck>
                  <Dashboard />
                </ProtectedRouteCheck>
              }
            />
            <Route
              path="/yourClients/:id"
              exact
              strict
              element={
                <ProtectedRouteCheck>
                  <Dashboard />
                </ProtectedRouteCheck>
              }
            />
            <Route
              path="/yourClients/goals/:id"
              exact
              strict
              element={
                <ProtectedRouteCheck>
                  <Dashboard />
                </ProtectedRouteCheck>
              }
            />
          </>
        )}
        {/* {publicRoutes.map((route, index) => (
          <Route
            path={route.path}
            key={index}
            exact
            strict
            element={
              <PublicRouteCheck>
                <route.component />
              </PublicRouteCheck>
            }
          />
        ))} */}

        {privateRoutes.map((route, index) => (
          <Route
            path={route.path}
            key={index}
            exact
            strict
            element={
              <PrivateRouteCheck>
                <route.component />
              </PrivateRouteCheck>
            }
          />
        ))}

        {protectedRoutes.map((route, index) => (
          <Route
            path={route.path}
            key={index}
            exact
            strict
            element={
              <ProtectedRouteCheck>
                <route.component />
              </ProtectedRouteCheck>
            }
          />
        ))}
        {/* <Route
          path="/"
          exact
          strict
          element={
            <PublicRoute>
              <Home />
            </PublicRoute>
          }
        /> */}
        <Route
          path="/dashboardlogin"
          exact
          strict
          element={
            <PublicRoute>
              <DashboardLogin />
            </PublicRoute>
          }
        />
        <Route
          path="/clientFormlogin"
          exact
          strict
          element={
            <PublicRouteCheck>
              <ClientLogin />
            </PublicRouteCheck>
          }
        />
        <Route
          path="/forgotpassword"
          exact
          strict
          element={
            <PublicRoute>
              <ForgotPassword />
            </PublicRoute>
          }
        />
        <Route
          path="/checkmail"
          exact
          strict
          element={
            <PublicRoute>
              <CheckEmail />
            </PublicRoute>
          }
        />
        <Route
          path="/resetpassword"
          exact
          strict
          element={
            <PublicRoute>
              <NewPassword />
            </PublicRoute>
          }
        />
        <Route
          path="/passwordresetsuccesfully"
          exact
          strict
          element={
            <PublicRoute>
              <PasswordSuccess />
            </PublicRoute>
          }
        />

        {/* <Route
          path="/user/pass"
          exact
          strict
          element={
            <PrivateRoute>
              <ChangePassword />
            </PrivateRoute>
          }
        /> */}

        {/* <Route path="clientProfile/:id" exact element={<Client />} /> */}

        {/* <Route
          path="/user/create"
          exact
          strict
          element={
            <ProtectedRouteCheck>
              <CreateUser />
            </ProtectedRouteCheck>
          }
        /> */}
        {/* <Route
          path="/technnician_dashboard"
          exact
          strict
          element={
            <ProtectedRouteCheck>
              <TechnicianDahaboard />
            </ProtectedRouteCheck>
          }
        /> */}

        {/* <Route
          path="/user/list"
          exact
          strict
          element={
            <ProtectedRouteCheck>
              <UserList />
            </ProtectedRouteCheck>
          }
        /> */}
        <Route
          path="/notFound"
          exact
          strict
          element={
            <PublicRoute>
              <PasswordSuccess />
            </PublicRoute>
          }
        />
        <Route
          path={"*"}
          strict
          element={
            !!!user?.role && loggingStatus ? (
              <PublicRoute>
                <NotFound />
              </PublicRoute>
            ) : (
              <Navigate to={"dashboardlogin"} replace />
            )
          }
        />
      </Routes>
    </Fragment>
  );
}

export default AppRoutes;

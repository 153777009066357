import React, { useEffect, useState } from "react";
import Styles from "./Dashboard.module.scss";
import RightSideBar from "../../components/PanelRightSidebar/RightSideBar";
import DashboardPanelIndex from "./DashboardPanelIndex";
import ClientsListing from "./ClientsListing";
import ClientBillingDetails from "./ClientBillingDetails";
import UnitListing from "./UnitListing";

import ProfileSetting from "./ProfileSetting";
import Client from "./Client/Client";
import ClientDailySessions from "./ClientDailySessions";
import Appointments from "../Appointment/Appointments";

import DailyDataSheetDetail from "../../components/forms/DailyDataSheetDetail/DailyDataSheetDetail";
import TechnicianDahaboard from "../TechnicianDahaboard/TechnicianDahaboard";
import DailyDataSheet from "../../components/forms/DailyDataSheet";
import Technican from "./Client/Technician";
import ClientTechListing from "./ClientTechListing";
import ClientDocuments from "./ClientDocuments";
import CheckInForm from "../../components/forms/CheckInForm/CheckInForm";
import CheckOutForm from "../../components/forms/CheckOutForm/CheckOutForm";
import AnalysisDashboard from "./AnalysisDashboard/AnalysisDashboard";

// import DailyDataSheet from "../../components/forms/DailyDataSheet";
const DashboardPanel = ({ dashboardView, setdashboardView }) => {
  const [panelView, setPanelView] = useState(dashboardView);
  const setPanelViewHandler = (view) => {
    setdashboardView(view);
    setPanelView(view);
  };
  // useEffect(() => {
  //   setPanelView(dashboardView);
  // }, [dashboardView]);

  return (
    <section className={Styles.Dashboard__Panel}>
      {dashboardView === "panel" && (
        <DashboardPanelIndex
          setPanelViewHandler={setPanelViewHandler}
          setdashboardView={setdashboardView}
        />
      )}
      {dashboardView === "panel" && (
        <RightSideBar setdashboardView={setdashboardView} />
      )}
      {dashboardView === "Directors" && (
        <ClientsListing
          RightSidebar={true}
          View={
            panelView === "Directors" || dashboardView === "Directors"
              ? "unarchived"
              : panelView
          }
          director={true}
          setdashboardView={setdashboardView}
          AllSessions={true}
          tabOf="director"
          // ClientSessions={true}
        />
      )}

      {dashboardView === "ClientBilling" && (
        <ClientBillingDetails
          setdashboardView={setdashboardView}
          tabOf="ClientBilling"
        />
      )}

      {dashboardView === "analysisDashboard" && (
        <AnalysisDashboard
          setdashboardView={setdashboardView}
          tabOf="analysisDashboard"
        />
      )}

      {dashboardView === "Clients" && (
        <ClientsListing
          RightSidebar={true}
          View={
            panelView === "Clients" || dashboardView === "Clients"
              ? "unarchived"
              : panelView
          }
          setdashboardView={setdashboardView}
          AllSessions={true}
          tabOf="client"
          // ClientSessions={true}
        />
      )}
      {dashboardView === "Technicians" && (
        <ClientsListing
          RightSidebar={true}
          View={
            panelView === "Technicians" || dashboardView === "Technicians"
              ? "unarchived"
              : panelView
          }
          setdashboardView={setdashboardView}
          AllSessions={true}
          tabOf="technician"
        />
      )}
      {dashboardView === "AllSessions" && (
        <ClientsListing
          RightSidebar={true}
          View={panelView}
          setdashboardView={setdashboardView}
          AllSessions={true}
        />
      )}
      {dashboardView === "yourclientsingle" && (
        <ClientTechListing
          View={"yourclientsingle"}
          setdashboardView={setdashboardView}
          dashboardView={dashboardView}
          //   AllSessions={true}
          tabOf="client"
          clientSessionsTech={true}
        />
      )}
      {dashboardView === "Sessions" && (
        <ClientTechListing
          View={"monthly"}
          setdashboardView={setdashboardView}
          dashboardView={dashboardView}
          //   AllSessions={true}
          tabOf="director"
          ClientSessions={true}
        />
      )}
      {dashboardView === "yourTechsingle" && (
        <ClientTechListing
          View={"yourclientsingle"}
          setdashboardView={setdashboardView}
          dashboardView={dashboardView}
          //   AllSessions={true}
          tabOf="technician"
          clientSessionsTech={true}
        />
      )}
      {dashboardView === "ProfileSetting" && <ProfileSetting />}
      {dashboardView === "Client" && (
        <Client
          setdashboardView={setdashboardView}
          View={dashboardView}
        ></Client>
      )}
      {dashboardView === "Technician" && (
        <Technican
          setdashboardView={setdashboardView}
          View={dashboardView}
        ></Technican>
      )}
      {dashboardView === "ViewGoals" && (
        <ClientDailySessions
          Panel={true}
          View={"ViewGoals"}
          setdashboardView={setdashboardView}
          ClientSessions={true}
          tabOf="client"
        />
      )}
      {dashboardView === "ArchivedGoals" && (
        <ClientDailySessions
          Panel={true}
          View={"ArchivedGoals"}
          setdashboardView={setdashboardView}
          ClientSessions={true}
          tabOf="client"
        />
      )}
      {dashboardView === "ClientDocuments" && (
        <ClientDocuments
          Panel={true}
          View={"ClientDocuments"}
          setdashboardView={setdashboardView}
          ClientSessions={true}
          tabOf="client"
        />
      )}
      {dashboardView === "AssignGoal" && (
        <ClientDailySessions
          Panel={true}
          View={"AssignGoal"}
          setdashboardView={setdashboardView}
          assignGoal={true}
          tabOf="client"
        />
      )}
      {dashboardView === "InProgressGoals" && (
        <ClientDailySessions
          Panel={true}
          View={"InProgressGoals"}
          setdashboardView={setdashboardView}
          InProgressGoals={true}
          tabOf={"technician"}
        />
      )}
      {dashboardView === "DailySessionView" && (
        <ClientDailySessions
          setdashboardView={setdashboardView}
          Panel={true}
          View={"DailySessionView"}
        />
      )}
      {dashboardView === "DailySessionView" && (
        <ClientDailySessions
          setdashboardView={setdashboardView}
          Panel={true}
          View={"DailySessionView"}
        />
      )}
      {dashboardView === "datasheet" && (
        <DailyDataSheet setdashboardView={setdashboardView} />
      )}

      {dashboardView === "serviceNote" && (
        <DailyDataSheetDetail setdashboardView={setdashboardView} />
      )}

      {dashboardView === "Appointments" && (
        <Appointments setdashboardView={setdashboardView} />
      )}
      {dashboardView === "Units" && (
        <UnitListing
          RightSidebar={true}
          View={
            panelView === "Units" || dashboardView === "Units"
              ? "original_target"
              : panelView
          }
          setdashboardView={setdashboardView}
          AllSessions={true}
          tabOf="client"
          // ClientSessions={true}
        />
      )}
      {dashboardView === "checkin" && (
        <CheckInForm setdashboardView={setdashboardView} />
      )}
      {dashboardView === "checkout" && (
        <CheckOutForm setdashboardView={setdashboardView} />
      )}
      {/* {dashboardView === "form" && */}
      {/* <DailyDataSheet /> */}
      {/* } */}
      {/* {dashboardView === "form" && <TechnicianDahaboard />} */}
      {/* {dashboardView === "form" && <DailyDataSheetDetail />} */}
      {/* <DailyDataSheet/> */}
    </section>
  );
};

export default DashboardPanel;

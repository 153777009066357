import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect } from "react";
import "./AnalysisDashboard.css";
import { useState } from "react";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import httpClient from "../../../services/axios";
import { handleErrorResponse } from "../../../helpers/responseHandler";
import LoaderSpinner from "../../../components/general/loader/loader.spinner";
import { createNotification } from "../../../helpers/createNotifications";
import fetchFormattedDateTime from "../../../utils/datetimeUtlis";

const InsightsOverview = ({ ClientSlug }) => {
  const [insightData, setInsightData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const fetchInsightData = async () => {
    if (ClientSlug) {
      setIsLoading(true);
      await sleep(1000);
      const queryParams = {
        user_id: ClientSlug,
        current_date: fetchFormattedDateTime()
      };
      const queryString = new URLSearchParams(queryParams).toString();
      try {
        const res = await httpClient.get(
          `api/v1/goals/client_goals_insight/?${queryString}`
        );
        if (res?.data) {
          setInsightData(res.data?.data);
        } else {
          createNotification("error", "Unable to fetch insight data");
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        handleErrorResponse(error);
      }
    }
  };

  useEffect(() => {
    fetchInsightData();
  }, [ClientSlug]);

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { name, time, amt } = payload[0].payload;
      return (
        <div className="custom-tooltip">
          <p className="label">
            <span style={{ fontWeight: "bold" }}>Goal Title:</span> {name}
          </p>
          <p className="label">
            <span style={{ fontWeight: "bold" }}>Mastery Time:</span> {`${time} hrs`}
          </p>
          <p className="label">
            <span style={{ fontWeight: "bold" }}>Tech Names:</span>{" "}
            {amt.join(", ")}
          </p>
        </div>
      );
    }
    return null;
  };

  if (isLoading) {
    return (
      <LoaderSpinner
        tip="Loading"
        size="50"
        top={"80%"}
        left={"80%"}
        color="#f6891f"
      />
    );
  }
  return (
    <div className="insight-overview" style={{ width: "100%", height: 300 }}>
      <ResponsiveContainer width="100%" height="95%">
        <BarChart
          data={insightData}
          margin={{
            top: 5,
            right: 10,
            left: 10,
            bottom: 25,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="mastery_date"
            label={{
              value: "Mastery Date",
              position: "insideBottom",
              style: { fontWeight: "bold" },
              offset: -20,
            }}
          />
          <YAxis
            label={{
              value: "Hours",
              angle: -90,
              position: "insideLeft",
              style: { fontWeight: "bold" },
            }}
          />
          <Tooltip content={<CustomTooltip />} /> {/* Custom Tooltip */}
          <Bar dataKey="time" fill="#B3CDAD" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default InsightsOverview;

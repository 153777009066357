import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState } from "react";
import "./AnalysisDashboard.css";
import AttendanceOverview from "./AttendanceOverview";
import InsightsAndWarnings from "./InsightsAndWarnings";
import TimeScoreGraph from "./TimeScoreGraph";
import ProgressReport from "./progressReport";
import CustomSelect from "../../Appointment/custom-select";

const AnalysisDashboard = ({ tabOf, setdashboardView }) => {
  // const [clients, setClients] = useState([]);
  const [ClientSlug, setClientSlug] = useState();
  const [clientSelected, setClientSelected] = useState(false);

  return (
    <div className="dashboard-wrapper">
      <div className="analysis-dashboard">
        <div className="dashboard-client-selection">
          <CustomSelect
            // label={"Select Client"}
            role="client"
            placeholder={"Select Client"}
            handleValue={(e) => setClientSlug(e)}
            field={"client_slug"}
            fieldValue={ClientSlug}
            labelClass={"visually-hidden"}
          />
        </div>
        <div className="row">
          {/* First Section (Client selection and Time Score Graph) */}
          <div className="col-md-6">
            <TimeScoreGraph ClientSlug={ClientSlug}></TimeScoreGraph>
          </div>

          {/* Second Section (Progress Report and Attendance Overview) */}
          <div className="col-md-6">
            <ProgressReport
              ClientSlug={ClientSlug}
              tabOf={tabOf}
              setdashboardView={setdashboardView}
            ></ProgressReport>
          </div>

          {/* Third Section (Insights) */}
          <div className="col-md-6">
            <AttendanceOverview ClientSlug={ClientSlug}></AttendanceOverview>
          </div>

          {/* Fourth Section (Warnings) */}
          <div className="col-md-6">
            <InsightsAndWarnings ClientSlug={ClientSlug}></InsightsAndWarnings>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalysisDashboard;

import "bootstrap/dist/css/bootstrap.min.css";
import dayjs from "dayjs";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import LinearGraph from "../../../components/Graphs/LinearGraph";
import DatePickerLM from "../../../components/form-controls/input/DatePicker/DatePickerLM";
import { createNotification } from "../../../helpers/createNotifications";
import { handleErrorResponse } from "../../../helpers/responseHandler";
import httpClient from "../../../services/axios";
import "./AnalysisDashboard.css";

const TimeScoreGraph = ({ ClientSlug }) => {
  const [selectedGoals, setSelectedGoals] = useState([]);
  const [filterParams, setFilterParams] = useState();
  const [clientGoals, setClientGoals] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [goalColorMap, setGoalColorMap] = useState({});

  const getDefaultDates = () => {
    const currentDate = moment();

    const startDate = currentDate.clone().subtract(1, "week").startOf("week");
    const endDate = currentDate.clone().subtract(1, "week").endOf("week");

    const formattedStartDate = startDate.format("YYYY-MM-DD");
    const formattedEndDate = endDate.format("YYYY-MM-DD");

    return {
      defaultStartDate: formattedStartDate,
      defaultEndDate: formattedEndDate,
    };
  };
  const { defaultStartDate, defaultEndDate } = getDefaultDates();

  const colorPalette = [
    "#8B0000",
    "#006400",
    "#00008B",
    "#8B008B",
    "#008B8B",
    "#8B4513",
    "#4B0082",
    "#2F4F4F",
    "#483D8B",
    "#FF4500",
    "#DC143C",
    "#B22222",
    "#9400D3",
    "#7FFF00",
    "#5F9EA0",
    "#8A2BE2",
    "#20B2AA",
    "#FF1493",
    "#5F9EA0",
    "#8A2BE2",
    "#4B0082",
    "#006400",
    "#483D8B",
    "#2F4F4F",
  ];

  const mapGoalColors = (goals) => {
    const colorMaps = {};
    goals.forEach((goal, index) => {
      colorMaps[goal.title] = colorPalette[index % colorPalette.length];
      setGoalColorMap(colorMaps);
    });
  };

  useEffect(() => {
    setSelectedGoals([]);
    setGraphData([]);
    setGoalColorMap({});
  }, [ClientSlug]);

  const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  useEffect(() => {
    const fetchClientGoals = async () => {
      await sleep(500);
      const queryParams = {
        page: 1,
        per_page: 400, // TODOS In the future, we will add search option
        user_id: ClientSlug,
        state: "un_archived",
      };
      const queryString = new URLSearchParams(queryParams).toString();
      try {
        const res = await httpClient.get(
          `/api/v1/goals/client_goals?${queryString}`
        );
        if (res?.data) {
          const goals = res.data?.goals;
          setClientGoals(goals);
          mapGoalColors(goals);
        } else {
          createNotification("error", "Unable to fetch Client Goals");
        }
      } catch (error) {
        handleErrorResponse(error);
      }
    };
    if (ClientSlug) {
      fetchClientGoals();
    }
  }, [ClientSlug]);

  const handleGoalsChange = (selectedGoals) => {
    setSelectedGoals(selectedGoals);
    const goalIds = selectedGoals.map((option) => option.value);
    setFilterParams((prev) => ({
      ...prev,
      goal_ids: goalIds,
    }));
  };

  const allFiltersPresent = () => {
    const { start_date, end_date, goal_ids } = filterParams || {};
    if (
      ((!!start_date && !!end_date) || (defaultStartDate && defaultEndDate)) &&
      Array.isArray(goal_ids) &&
      goal_ids.length > 0
    ) {
      return true;
    } else {
      setGraphData([]);
      return false;
    }
  };

  useEffect(() => {
    const goalTimeScoreGraph = async () => {
      const queryParams = {
        user_id: ClientSlug,
        start_date: filterParams.start_date || defaultStartDate,
        end_date: filterParams.end_date || defaultEndDate,
      };
      const queryString = new URLSearchParams(queryParams).toString();
      try {
        const res = await httpClient.get(
          `/api/v1/goals/goal_time_score_graph?${queryString}`,
          {
            params: {
              goal_ids: filterParams.goal_ids,
            },
          }
        );
        if (res?.data) {
          setGraphData(res.data?.graph_data);
        } else {
          createNotification("error", "Unable to fetch Graph Data");
        }
      } catch (error) {
        handleErrorResponse(error);
      }
    };
    if (allFiltersPresent()) {
      goalTimeScoreGraph();
    }
  }, [filterParams]);

  console.log("goalColorMap", goalColorMap);

  return (
    <div className="time-score-graph">
      <div className="text-center mb-15">
        <h4 className="fw-bold">Time Score Graph</h4>
      </div>
      <div className="mb-10 time-score-graph-filters">
        <DatePickerLM
          label={"Start Date"}
          defaultValue={defaultStartDate}
          onChange={(date) => {
            const formattedDate = date
              ? dayjs(date).format("YYYY-MM-DD")
              : null;
            setFilterParams({ ...filterParams, start_date: formattedDate });
          }}
        ></DatePickerLM>

        <DatePickerLM
          label={"End Date"}
          defaultValue={defaultEndDate}
          onChange={(date) => {
            const formattedDate = date
              ? dayjs(date).format("YYYY-MM-DD")
              : null;
            setFilterParams({ ...filterParams, end_date: formattedDate });
          }}
        ></DatePickerLM>
        <div className="d-flex flex-column gap-2 flex-grow-1">
          <label className="lm-input-form-input-label">Select Goals</label>
          <div className="multi-selector">
            <Select
              isMulti
              options={clientGoals.map((goal) => ({
                label: goal.title,
                value: goal.id,
              }))}
              value={selectedGoals}
              onChange={handleGoalsChange}
              placeholder="Select Goals"
              className="w-100"
              isDisabled={!ClientSlug}
              styles={{
                multiValue: (styles, { data }) => {
                  return {
                    ...styles,
                    backgroundColor: goalColorMap[data.label],
                    borderRadius: "5px",
                  };
                },
                multiValueLabel: (styles, { data }) => ({
                  ...styles,
                  color: "white",
                }),
              }}
            />
          </div>
        </div>
      </div>
      <div className="line-graph">
        <LinearGraph
          graphData={graphData}
          goalColorMap={goalColorMap}
        ></LinearGraph>
      </div>
    </div>
  );
};

export default TimeScoreGraph;

import { Input } from "antd";
import { useEffect, useState } from "react";
import { useMatch, useParams } from "react-router-dom";
import CustomSelect from "../../Containers/Appointment/custom-select";
import { greenPostive, redNegetive } from "../../constants/constants";
import { createNotification } from "../../helpers/createNotifications";
import httpClient from "../../services/axios";
import { logout } from "../../store/user/userSlice";
import { TextFieldFormik } from "../form-controls/input/text.field.formik";
import "./DailyDataSheetTabs.scss";
import { handleErrorResponse } from "../../helpers/responseHandler";

const AddMands = ({
  serviceNoteApproved,
  restrictedFieldNumbers,
  markAsCompleted,
  isDataSheetLocked,
  intializeAutoSaveRequests,
  setIntializeAutoSaveRequests,
}) => {
  const saveBtnText = serviceNoteApproved ? "Approved" : "Save";

  const { params } = useMatch("*");
  const route = Object.values(params)[0];
  const { id, serviceId } = useParams();
  const [action, setAction] = useState(false);
  const [saveInProgress, SetSaveInProgress] = useState(false);
  const [payload, setPayload] = useState([
    {
      mand_type: "",
      title: "",
      count: 1,
    },
  ]);
  const mands = [
    { value: "Prompted", key: "promted" },
    { value: "Unprompted", key: "unpromted" },
    { value: "Spontaneous", key: "spontaneous" },
  ];

  useEffect(() => {
    const getMandsData = async () => {
      try {
        if (payload?.length) {
          const res = await httpClient.get("api/v1/mands", {
            params: { data_sheet: { id: id } },
          });
          if (res.data?.data.length) {
            setPayload(res.data.data);
            markAsCompleted("mands");
          } else {
            setPayload(payload);
          }
          return true;
        }
      } catch (error) {
        handleErrorResponse(error);
      }
    };
    if (id && (route.includes("datasheet") || route.includes("dataSheet"))) {
      getMandsData();
    }
  }, [id, action]);

  const hanldeSave = async () => {
    try {
      SetSaveInProgress(true)
      if (payload.length) {
        const res = await httpClient.post("/api/v1/mands/update_or_create", {
          data_sheet: {
            id: id,
            mands_attributes: payload,
          },
        });
        if (res.data?.data) {
          setPayload(res.data.data);
          setAction(!action);
          markAsCompleted("mands");
          createNotification("success", "Mands Saved");
        }
        SetSaveInProgress(false)
        return true;
      }
    } catch (error) {
      SetSaveInProgress(false)
      handleErrorResponse(error);
    }
  };

  const handleAutoSave = async () => {
    const validItems = [];
    const inValidItems = [];

    payload.forEach((item) => {
      if (!!item.id || (!!item?.title && !!item?.mand_type)) {
        validItems.push(item);
      } else {
        inValidItems.push(item);
      }
    });
    try {
      if (validItems.length) {
        const res = await httpClient.post("/api/v1/mands/update_or_create", {
          data_sheet: {
            id: id,
            mands_attributes: validItems,
          },
        });

        const data = res.data?.data || [];
        if (data) {
          const updatedPayload = [...data, ...inValidItems];
          setPayload(updatedPayload);
        }
        return true;
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  useEffect(() => {
    if (intializeAutoSaveRequests) {
      console.log("Saving Mands");
      handleAutoSave();
      setIntializeAutoSaveRequests(false);
    }
  }, [intializeAutoSaveRequests]);

  const disableButton =
    !!payload.length &&
    payload.every((obj) => !!obj?.title.length && !!obj.mand_type.length);

  const validNoOfMands =
    payload?.length && payload?.length >= restrictedFieldNumbers;

  return (
    <>
      <div className="compilance-header">
        <div style={{ marginTop: "10px" }}>
          <h6 style={{ color: "red" }}>
            Note: You have to enter {restrictedFieldNumbers} Mands Minimum
          </h6>
        </div>
        {payload?.length > 0 &&
          payload?.map((item, index) => {
            return (
              <div className="demand-wrapper" key={index}>
                <ul className="demand-wrapper-ul-mand">
                  <li>
                    <CustomSelect
                      label={`Mand ${index + 1}`}
                      className="mands-select"
                      simple={true}
                      time={false}
                      objectives={false}
                      options={mands}
                      mands={true}
                      placeholder={"Select"}
                      handleValue={(value) => {
                        const updatedPayload = [...payload];
                        updatedPayload[index].mand_type = value;
                        setPayload(updatedPayload);
                      }}
                      field={"location"}
                      fieldValue={item?.mand_type}
                      name={
                        mands.find((opt) => opt.key === item?.mand_type)?.value
                      }
                    />
                  </li>
                  <li style={{ minWidth: "120px" }}>
                    <div>
                      <label className="f-15 f-w-600 clr-dark-grey">
                        Description {index + 1}
                      </label>
                    </div>

                    <TextFieldFormik
                      key={item?.index}
                      placeholder={"Enter Description"}
                      type={"text"}
                      wrapperClassName={"max-w-input-of w-100"}
                      tabIndex={1}
                      name={`title`}
                      handleChange={(e) => {
                        const updatedPayload = [...payload];
                        updatedPayload[index].title = e.target.value;
                        setPayload(updatedPayload);
                      }}
                      values={item?.title || ""}
                    />
                  </li>

                  <li>
                    <Input
                      className="counter-input-abc"
                      style={{
                        // maxWidth: "120px",
                        height: "42px",
                      }}
                      prefix={
                        <span
                          onClick={() => {
                            if (payload[index]?.count === 1) {
                              return;
                            } else {
                              const updatedPayload = [...payload];
                              updatedPayload[index].count =
                                parseInt(item?.count) - 1;
                              setPayload(updatedPayload);
                            }
                          }}
                        >
                          {redNegetive}
                        </span>
                      }
                      suffix={
                        <span
                          onClick={() => {
                            const updatedPayload = [...payload];
                            updatedPayload[index].count =
                              parseInt(item?.count) + 1;
                            setPayload(updatedPayload);
                          }}
                        >
                          {greenPostive}
                        </span>
                      }
                      type="number"
                      min={0}
                      onChange={(e) => {
                        const trimmedValue = e.target.value.replace(/^0+/, "");
                        const numericValue = parseInt(trimmedValue);
                        const updatedPayload = [...payload];

                        if (isNaN(numericValue) || numericValue < 1) {
                          setPayload({ ...payload, count: 1 });
                          updatedPayload[index].count = 1;
                          setPayload(updatedPayload);
                        } else {
                          updatedPayload[index].count = numericValue;
                          setPayload(updatedPayload);
                        }
                      }}
                      value={item?.count}
                    />
                  </li>
                </ul>
              </div>
            );
          })}
      </div>
      <div className="objective-btns-wrapper">
        <button
          onClick={() => {
            const newObject = {
              mand_type: "",
              title: "",
              count: 1,
            };
            setPayload([...payload, newObject]);
          }}
          className="add-main-btn"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
          >
            <path d="M6 0V12" stroke="#ABABAB" />
            <path d="M12 6L-1.78814e-07 6" stroke="#ABABAB" />
          </svg>
        </button>
        <button
          onClick={() => hanldeSave()}
          className="save-btn"
          disabled={
            !disableButton ||
            serviceNoteApproved ||
            !validNoOfMands ||
            isDataSheetLocked ||
            saveInProgress
          }
        >
          {saveBtnText}
        </button>
      </div>
    </>
  );
};
export default AddMands;

import { Input } from "antd";
import { useEffect, useState } from "react";
import { useMatch, useParams } from "react-router-dom";
import CustomSelect from "../../Containers/Appointment/custom-select";
import { greenPostive, redNegetive } from "../../constants/constants";
import { createNotification } from "../../helpers/createNotifications";
import httpClient from "../../services/axios";
import { logout } from "../../store/user/userSlice";
import { TextFieldFormik } from "../form-controls/input/text.field.formik";
import { handleErrorResponse } from "../../helpers/responseHandler";

const AddAccNo = ({
  serviceNoteApproved,
  restrictedFieldNumbers,
  markAsCompleted,
  isDataSheetLocked,
  intializeAutoSaveRequests,
  setIntializeAutoSaveRequests,
}) => {
  const saveBtnText = serviceNoteApproved ? "Approved" : "Save";
  const { id, serviceId } = useParams();
  const newObject = {
    description: "",
    count: 1,
    action: false,
  };
  const [saveInProgress, SetSaveInProgress] = useState(false);
  const [payload, setPayload] = useState([
    {
      description: "",
      count: 1,
      action: "",
    },
  ]);
  const { params } = useMatch("*");
  const route = Object.values(params)[0];

  useEffect(() => {
    const getDataAccNo = async () => {
      try {
        const res = await httpClient.get(`api/v1/acceptance_details`, {
          params: {
            data_sheet: {
              id: id,
            },
          },
        });
        const { data } = res || {};

        if (data.length) {
          setPayload(data);
          markAsCompleted("acceptingNo");
        }
      } catch (error) {
        handleErrorResponse(error);
      }
    };
    if (id && (route.includes("datasheet") || route.includes("dataSheet"))) {
      getDataAccNo();
    }
  }, [id]);

  const hanldeSave = async () => {
    try {
      SetSaveInProgress(true)
      const res = await httpClient.post(
        `api/v1/acceptance_details/update_or_create`,

        { data_sheet: { id: id, acceptance_details_attributes: [...payload] } }
      );
      const { data } = res || {};

      if (data) {
        setPayload(data);
        markAsCompleted("acceptingNo");
        createNotification("success", "Accepting No Updated");
      }
      SetSaveInProgress(false)
      return true;
    } catch (error) {
      SetSaveInProgress(false)
      handleErrorResponse(error);
    }
  };

  useEffect(() => {
    if (intializeAutoSaveRequests) {
      console.log("Saving accepting nos");
      handleAutoSave();
      setIntializeAutoSaveRequests(false);
    }
  }, [intializeAutoSaveRequests]);

  const handleAutoSave = async () => {
    const validItems = [];
    const inValidItems = [];

    payload.forEach((item) => {
      if (
        !!item.id ||
        (!!item?.description &&
          (item?.action === "yes" || item?.action === "no"))
      ) {
        validItems.push(item);
      } else {
        inValidItems.push(item);
      }
    });
    try {
      if (validItems?.length) {
        const res = await httpClient.post(
          `api/v1/acceptance_details/update_or_create`,

          {
            data_sheet: {
              id: id,
              acceptance_details_attributes: [...validItems],
            },
          }
        );
        const { data } = res || {};
        if (data) {
          const updatedPayload = [...data, ...inValidItems];
          setPayload(updatedPayload);
        }
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const radioOption = [
    { value: "Yes", key: "yes" },
    { value: "No", key: "no" },
  ];

  const disableButton =
    !!payload.length &&
    payload.every((obj) => !!obj?.description.length && !!obj.action.length);

  const validNoOfAccNos =
    payload?.length && payload?.length >= restrictedFieldNumbers;

  return (
    <>
      <div className="compilance-header">
        <div style={{ marginTop: "10px" }}>
          <h6 style={{ color: "red" }}>
            Note: You have to enter {restrictedFieldNumbers} Accepting no's
            Minimum
          </h6>
        </div>
        {payload?.length > 0 &&
          payload?.map((item, index) => {
            return (
              <div className="demand-wrapper" key={index}>
                <ul className="demand-wrapper-ul-mand">
                  <li style={{ minWidth: "120px" }}>
                    <div>
                      <label className="f-15 f-w-600 clr-dark-grey">
                        Description {index + 1}
                      </label>
                    </div>

                    <TextFieldFormik
                      key={item?.index}
                      placeholder={"Enter Title"}
                      type={"text"}
                      wrapperClassName={"max-w-input-of w-100"}
                      tabIndex={1}
                      name={`title`}
                      handleChange={(e) => {
                        const updatedPayload = [...payload];
                        updatedPayload[index].description = e.target.value;
                        setPayload(updatedPayload);
                      }}
                      values={item?.description || ""}
                    />
                  </li>

                  <li>
                    <CustomSelect
                      label={`Accepted No ${index + 1}`}
                      className="mands-select"
                      simple={true}
                      time={false}
                      objectives={false}
                      options={radioOption}
                      mands={true}
                      placeholder={"Select"}
                      handleValue={(value) => {
                        const updatedPayload = [...payload];
                        updatedPayload[index].action = value;
                        setPayload(updatedPayload);
                      }}
                      field={"location"}
                      fieldValue={item?.action}
                      name={
                        radioOption.find((opt) => opt.key === item?.action)
                          ?.value
                      }
                    />
                  </li>
                  <li>
                    {" "}
                    <Input
                      className="counter-input-abc"
                      style={{
                        // maxWidth: "120px",
                        height: "42px",
                      }}
                      prefix={
                        <span
                          onClick={() => {
                            if (payload[index]?.count === 1) {
                              return;
                            } else {
                              const updatedPayload = [...payload];
                              updatedPayload[index].count =
                                parseInt(item?.count) - 1;
                              setPayload(updatedPayload);
                            }
                          }}
                        >
                          {redNegetive}
                        </span>
                      }
                      suffix={
                        <span
                          onClick={() => {
                            const updatedPayload = [...payload];
                            updatedPayload[index].count =
                              parseInt(item?.count) + 1;
                            setPayload(updatedPayload);
                          }}
                        >
                          {greenPostive}
                        </span>
                      }
                      type="number"
                      min={1}
                      onChange={(e) => {
                        const trimmedValue = e.target.value.replace(/^0+/, "");
                        const numericValue = parseInt(trimmedValue);
                        const updatedPayload = [...payload];

                        if (isNaN(numericValue) || numericValue < 1) {
                          setPayload({ ...payload, count: 1 });
                          updatedPayload[index].count = 1;
                          setPayload(updatedPayload);
                        } else {
                          updatedPayload[index].count = numericValue;
                          setPayload(updatedPayload);
                        }
                      }}
                      value={item?.count}
                    />
                  </li>
                </ul>
              </div>
            );
          })}
      </div>
      <div className="objective-btns-wrapper">
        <button
          onClick={() => setPayload([...payload, newObject])}
          className="add-main-btn"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
          >
            <path d="M6 0V12" stroke="#ABABAB" />
            <path d="M12 6L-1.78814e-07 6" stroke="#ABABAB" />
          </svg>
        </button>
        <button
          onClick={() => hanldeSave()}
          className="save-btn"
          disabled={
            !disableButton ||
            serviceNoteApproved ||
            !validNoOfAccNos ||
            isDataSheetLocked || 
            saveInProgress
          }
        >
          {saveBtnText}
        </button>
      </div>
    </>
  );
};

export default AddAccNo;

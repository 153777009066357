import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import "./AnalysisDashboard.css";
import Select from "react-select";
import { handleErrorResponse } from "../../../helpers/responseHandler";
import httpClient from "../../../services/axios";
import LoaderSpinner from "../../../components/general/loader/loader.spinner";
import { createNotification } from "../../../helpers/createNotifications";

const AttendanceOverview = ({ ClientSlug }) => {
  const [unitStats, setUnitStats] = useState({});
  const [sessionStats, setSessionStats] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isUnitAssosiated, setIsUnitAssosiated] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState({
    value: "97153",
    label: "97153",
  });
  const unitCodes = [
    { value: "97151", label: "97151" },
    { value: "97152", label: "97152" },
    { value: "97153", label: "97153" },
    { value: "97154", label: "97154" },
    { value: "97155", label: "97155" },
    { value: "97156", label: "97156" },
    { value: "97157", label: "97157" },
  ];

  const clientUnitOverview = async ({ unitCode }) => {
    if (ClientSlug) {
      setIsLoading(true);
      const queryParams = {
        unit_code: unitCode,
        user_id: ClientSlug,
      };
      const queryString = new URLSearchParams(queryParams).toString();
      try {
        const res = await httpClient.get(
          `api/v1/units/client_unit_overview/?${queryString}`
        );
        if (res?.data) {
          setIsUnitAssosiated(true);
          setUnitStats(res?.data?.data);
        } else {
          createNotification("error", "Unable to fetch Client Unit Overview");
        }
        setIsLoading(false);
      } catch (error) {
        setIsUnitAssosiated(false);
        setIsLoading(false);
        handleErrorResponse(error);
      }
    }
  };

  const ClientAttendanceOverview = async () => {
    if (ClientSlug) {
      const queryParams = {
        user_id: ClientSlug,
      };
      const queryString = new URLSearchParams(queryParams).toString();
      try {
        const res = await httpClient.get(
          `api/v1/appointments/appointment_stats/?${queryString}`
        );
        if (res?.data) {
          setSessionStats(res?.data?.data);
        } else {
          createNotification("error", "Unable to fetch Client Unit Overview");
        }
      } catch (error) {
        handleErrorResponse(error);
      }
    }
  };

  useEffect(() => {
    // clientUnitOverview({ unitCode: 97153 });
    setSelectedUnit({
      value: "97153",
      label: "97153",
    });
    ClientAttendanceOverview();
  }, [ClientSlug]);

  useEffect(() => {
    clientUnitOverview({ unitCode: selectedUnit.value });
  }, [selectedUnit]);

  if (isLoading) {
    return (
      <LoaderSpinner
        tip="Loading"
        size="50"
        top={"50%"}
        left={"39%"}
        color="#f6891f"
      />
    );
  }

  return (
    <div className="attendance-overview">
      <div className="text-center mb-15">
        <h4 className="fw-bold">Attendance Overview</h4>
      </div>
      <div className="d-flex flex-column gap-2 flex-grow-1">
        <label className="lm-input-form-input-label">Select Unit Code</label>
        <div className="">
          <Select
            options={unitCodes}
            value={selectedUnit}
            onChange={(e) => {
              setSelectedUnit(e);
            }}
            className="w-100"
            isDisabled={!ClientSlug || isLoading}
          />
        </div>
      </div>
      {ClientSlug && (
        <>
          <div className="unit-stats mt-20 mb-10">
            <label className="lm-input-form-input-label">Unit Stats</label>
            {isUnitAssosiated && (
              <div className="progress">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{
                    width: `${unitStats.used_units}%`,
                    minWidth: "100px",
                  }}
                  aria-valuenow={unitStats.used_units}
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  <span className="progress-text">
                    {`Used ${unitStats.used_units} %`}
                  </span>
                </div>
                <div
                  className="progress-bar bg-success"
                  role="progressbar"
                  style={{
                    width: `${unitStats.remaining_units}%`,
                    minWidth: "100px",
                  }}
                  aria-valuenow={unitStats.remaining_units}
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  <span className="progress-text">
                    {`Remaining ${unitStats.remaining_units} %`}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className="session-stats mt-20">
            <label className="lm-input-form-input-label">Session Stats</label>
            <div className="progress">
              <div
                className="progress-bar bg-success"
                role="progressbar"
                style={{
                  width: `${sessionStats.attended}%`,
                  minWidth: "100px",
                }}
                aria-valuenow={sessionStats.attended}
                aria-valuemin="0"
                aria-valuemax="100"
              >
                <span className="progress-text">
                  {`Attended ${sessionStats.attended} %`}
                </span>
              </div>
              <div
                className="progress-bar"
                role="progressbar"
                style={{
                  width: `${sessionStats.missed}%`,
                  minWidth: "100px",
                }}
                aria-valuenow={sessionStats.missed}
                aria-valuemin="0"
                aria-valuemax="100"
              >
                <span className="progress-text">
                  {`Missed ${sessionStats.missed} %`}
                </span>
              </div>
              <div
                className="progress-bar bg-danger"
                role="progressbar"
                style={{
                  width: `${sessionStats.cancelled}%`,
                  minWidth: "100px",
                }}
                aria-valuenow={sessionStats.cancelled}
                aria-valuemin="0"
                aria-valuemax="100"
              >
                <span className="progress-text">
                  {`Cancelled ${sessionStats.cancelled} %`}
                </span>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AttendanceOverview;
